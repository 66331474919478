import React from "react";

import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import useQuestionForm from "./hooks";
import { useQuestionFormStyles } from "./question-form.css";
import { Answer } from "../../../models";
import { QuestionTypes } from "../../../common/enums/question-types.enum";

interface Props {
  answer?: Answer;
  options?: string[];
  questionId: string;
  type: QuestionTypes;
}

const QuestionForm = ({ answer, options, questionId, type }: Props) => {

  const classes = useQuestionFormStyles();
  const { formData, handleChange, handleSubmit, invalidData, loading, modified, resetValues, showMoreDetails } = useQuestionForm({ answer, questionId });
    
	return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>        
        <Grid item xs={12}>
          {
            options 
            ? type === QuestionTypes.RADIO_GROUP || type === QuestionTypes.RADIO_GROUP_FREE_TEXT
              ? (
                  <RadioGroup name="value" value={formData.value} className={classes.options} onChange={handleChange} >
                    {options.map( (option, index) => <FormControlLabel key={index} value={option} label={option} control={<Radio />}></FormControlLabel>)}
                    {type === QuestionTypes.RADIO_GROUP_FREE_TEXT ? <FormControlLabel value="other" label="Other" control={<Radio />}></FormControlLabel> : null}
                  </RadioGroup>
                )
              : (
                <FormGroup row >
                  {options.map( (option, index) => <FormControlLabel key={index} label={option} control={<Checkbox checked={formData.value.split(",").find(value => value === option) ? true : false} onChange={handleChange} name={option} value={option} />}></FormControlLabel>)}
                  {type === QuestionTypes.MULTI_CHOICE_FREE_TEXT ? <FormControlLabel label="Other" control={<Checkbox checked={formData.value.split(",").find(value => value === "other") ? true : false} value="other" onChange={handleChange} />}></FormControlLabel> : null}
                </FormGroup>
              )
            : null
          }
        </Grid>
        {
          !options || showMoreDetails
          ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    name="moreDetails"
                    value={formData.moreDetails}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            )
          : null
        }
        {
          modified
          ? (
            <Grid item xs={12} className={classes.buttons}>
              <Button
                className={classes.button}
                disabled={ loading }
                type="submit"
                variant="contained"
                color="primary">
                Save
              </Button>
              <Button
                onClick={resetValues}
                className={classes.button}
                disabled={ loading }
                variant="contained"
                color="secondary">
                Cancel
              </Button>
            </Grid>
          )
          : null
        }
        {
          invalidData
          ? (
              <Grid item xs={12}>
                <Typography className={classes.invalidData}>Sorry, but you have to give an answer</Typography>                
              </Grid>
            )
          : null
        }
      </Grid>
    </form>
	);
};

export default QuestionForm;