import React, { ReactNode, useReducer } from 'react';
import { APINotificationContext } from './api-notification.context';
import { APINotificationReducer } from './api-notification.reducer';

interface Props {
	children: ReactNode;
}

const APINotificationProvider = ({ children }: Props) => {
  
  const [ notification, dispatchNotification ] = useReducer(APINotificationReducer, null as any);
  
  return (        
    <APINotificationContext.Provider value={{notification, dispatchNotification}}>
      {children}
    </APINotificationContext.Provider>
  );   
}

export default APINotificationProvider;