import React, { ReactNode } from 'react';
import { Link as RouterLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CircularProgress, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import useFetchCSRF from '../../common/hooks/useFetchCSRF';
import useNewPassword from './hooks';

const useStyles = makeStyles((theme) => ({
	bg: {
		height: "100vh",
		boxSizing: "border-box",
		paddingTop: 50,
		background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
		fallbacks: {
			background: theme.palette.primary.main,
		}
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 25,
		boxSizing: "border-box",
	},
	form: {
		width: '100%'
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	alert: {
		alignSelf: "normal"
	}
}));

interface Props {
	successText?: string | ReactNode;
	hideTitleOnSuccess?: boolean;
}

const Form = ({ successText, hideTitleOnSuccess }: Props) => {
	const classes = useStyles();
	const { CSRF } = useFetchCSRF();
	const { error, handleSubmit, loading, onFieldChange, success, values } = useNewPassword();

	const isLoading = loading || CSRF.loading;

	return (
		<Container maxWidth="sm" disableGutters style={{ margin: 0 }}>
			{hideTitleOnSuccess && success ? null : (
				<Typography display="block" align="left" component="h1" variant="h2">
					Set a new password
				</Typography>
			)}

			{!success &&
				<Box mt={1} mb={1} color="text.secondary">
					<Typography variant="body2">
						Please enter a password in the inputs below to set a new password, it should contain at least 6 characters, 1 lowercase letter, 1 uppercase letter and 1 digit.
					</Typography>
				</Box>}

			{!success && (
				<form className={classes.form} onSubmit={handleSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						inputProps={{ minLength: 6 }}
						name="newPassword"
						label="New password"
						type="password"
						id="password"
						autoComplete="New password"
						value={values.newPassword}
						onChange={onFieldChange}
					/>

					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						inputProps={{ minLength: 6 }}
						name="confirmPassword"
						label="Confirm password"
						type="password"
						id="confirmPassword"
						autoComplete="Confirm password"
						value={values.confirmPassword}
						onChange={onFieldChange}
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						disabled={isLoading}
						className={classes.submit}>
						{isLoading ? <CircularProgress size={25} color="primary" /> : "Set New Password"}
					</Button>
				</form>
			)}

			{success &&
				<Alert className={classes.alert} severity="success">
					{successText ? successText : <span>Your password has been successfully reset. You may now <Link component={RouterLink} to="/login" variant="body2">Login</Link></span>}
				</Alert>}

			{error && <Alert className={classes.alert} severity="error">{error}</Alert>}
		</Container>
	);
}

export default Form;