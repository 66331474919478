import { FormEvent, useContext, useEffect, useState } from "react"
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";

import { Answer } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

interface iFormData {
  moreDetails: string;
	value: string;
}

interface Props {
  answer?: Answer;
  questionId: string;
}

const initialData = { moreDetails: "", value: "" }

export default function useQuestionForm({ answer, questionId }: Props) {
  
  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useContext(APINotificationContext);

  const [ formData, setFormData ] = useState<iFormData>(initialData);
  const [ showMoreDetails, setShowMoreDetails ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ modified, setModified ] = useState<boolean>(false);
  const [ invalidData, setInvalidaData ] = useState<boolean>(false);

  useEffect(() =>{

    if(answer){
      setFormData({
        moreDetails: answer.moreDetails,
        value: answer.value,
      })
      if(answer.moreDetails && answer.moreDetails !== ""){
        setShowMoreDetails(true);
      }
    }
  }, [answer])

  function handleChange (event: any) {

    const { name, value } = event.target;

    if(name !== "value" && name !== "moreDetails"){
      
      const newValue = formData.value === "" 
        ? value 
        : formData.value.split(',').includes(value)
          ? formData.value.split(',').filter(selectedValue => selectedValue !== value).join(',')
          : formData.value + `,${value}`;      

      setFormData((prevFormData) => ({ ...prevFormData, value: newValue }));

      if(value === "other"){
        toggleShowMoreDetails();
      }
    } else {      
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));

      if((value === "other") || (showMoreDetails && name !== "moreDetails")){
        toggleShowMoreDetails();
      }
    }

    if(!modified){
      setModified(true);
    }

    if(invalidData){
      setInvalidaData(false);
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {

    event.preventDefault();
    try {
			setLoading(true);

			const data: any = {
				value: formData.value !== "" ? formData.value : null,
        moreDetails: formData.moreDetails !== "" ? formData.moreDetails : null,
        questionId: questionId,
        consentFormId: consentForm.id
      }
      
      if(!data.value && !data.moreDetails){
        setInvalidaData(true);
      } else {

        const res = answer ? await apiInstance.patch(`/answers/${answer.id}`, data): await apiInstance.post(`/answers`, data);
  
        if (res.status === 201 || res.status === 200) {
          res.status === 201 ? dispatch({ type: ConsentFormProviderActionType.ADD_ANSWER, payload: res.data}) : dispatch({ type: ConsentFormProviderActionType.UPDATE_ANSWER, payload: res.data});
        }
      }
		} catch (error) {
			console.log(error);
      dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to save your response", severity: Severity.ERROR } });
		} finally {
			setLoading(false);
      setModified(false);
		}
  }

  function toggleShowMoreDetails() {

    if(showMoreDetails && formData.moreDetails !== ""){      
      setFormData((prevFormData) => ({ ...prevFormData, moreDetails: "" }));
    }

    setShowMoreDetails(!showMoreDetails);
  }

  function resetValues () {
    
    answer ? setFormData({ moreDetails: answer.moreDetails, value: answer.value, }) : setFormData(initialData);
    setModified(false);
  }

	return {
    formData,
    handleChange,
    handleSubmit,
    invalidData,
    loading,
    modified,
    resetValues,
    showMoreDetails,
	}
}