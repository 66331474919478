import { AppBar, Box, Container, Grid, Toolbar, Typography } from "@material-ui/core";
import { EmailOutlined } from "@material-ui/icons";
import React, { Fragment, ReactNode } from "react";
import { Helmet } from "react-helmet";
import Copyright from "../copyright";
import Footer from "../footer";
import { useLoggedOutStyles } from "./logged-out.css";

interface Props {
	children: ReactNode;
	title?: string;
	// All other props
	[x: string]: any;
}

const LoggedOut = ({ children, ...otherProps }: Props) => {
	const classes = useLoggedOutStyles();
	
	return (
		<main>
			<Helmet>
				<title>Medical Consent</title>
			</Helmet>
			<AppBar position="relative" color="transparent" className={classes.appBar}>
				<Toolbar variant="dense">
					<Container maxWidth="lg">
						<Grid container justify="space-between" alignItems="center">
							<img className={classes.logo} src="/images/Medical_Consent_Logo.svg" width="225px" alt="Medical Consent" />

							<Box>
								<Box mt={1} display="flex" alignItems="center" justifyContent="flex-end">
									<EmailOutlined color="primary" style={{ marginRight: 10 }} />
									<Typography variant="h6" color="inherit" noWrap style={{ margin: 0 }}>
										support@medicalconsent.co.uk
									</Typography>
								</Box>

							</Box>
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>

			<Box className={classes.contentContainer} paddingTop={5} paddingBottom={5} boxSizing="border-box" component="main">
				<Container maxWidth="lg">
					<Fragment>{children}</Fragment>
				</Container>
			</Box>
			<Footer />
		</main>
	);
}

export default LoggedOut;