import { Box, Typography } from "@material-ui/core";
import React from "react";
import Signature from "../signature";
import useSignatures from "./hooks";

const Signatures = () => {
  // Get resources that are signatures
  const { getSignatureByType } = useSignatures();

  return (
    <Box component="section">
      <Typography component="h2" variant="h2">
        Signatures
      </Typography>
      <Box fontSize={16}>
        <ul>
          <li style={{ marginBottom: 8 }}>
            I understand that no guarantee is given that a particular person
            will perform my procedure. However, the person performing my
            procedure will be appropriately qualified, experienced and
            supervised where necessary.
          </li>
          <li style={{ marginBottom: 8 }}>
            I understand that I will have the opportunity to discuss the details
            of anaesthesia with an anaesthetist before the procedure, unless the
            urgency of my situation prevents this. (This only applies to
            patients having general or regional anaesthesia).
          </li>
          <li style={{ marginBottom: 8 }}>
            I understand that any procedure in addition to those described above
            will only be carried out if it is necessary to save my life or to
            prevent serious harm to my health. I have been told about additional
            procedures which may become necessary during my treatment and have
            indicated above in the considerations those things I do or do not
            wish to happen and any other relevant health issues or allergies.
          </li>
          <li>I agree to the procedure or course of treatment above.</li>
        </ul>
      </Box>
      {/* TODO - make sure all wording is signed off by Neil */}
      <Signature
        existingSignature={getSignatureByType("Patient")}
        name="Patient signature"
        type="Patient"
        desc="Please use the SIGN button below to create a signature or sign in 
		the box using your mouse (desktop) or finger (tablet/mobile). By doing 
		this you confirm your agreement."
      />
      <Signature
        existingSignature={getSignatureByType("Witness")}
        name="Witness signature (optional)"
        type="Witness"
        desc="A witness should sign below if the patient is unable to sign for 
		themselves but has indicated his or her consent. Young people/children 
		may also like a parent to sign here. Please use the SIGN button below 
		to create a signature or sign in the box using your mouse (desktop) or 
		finger (tablet/mobile). By doing this you agree that the patient has 
		understood and indicated their agreement."
      />
      <Signature
        existingSignature={getSignatureByType("Interpreter")}
        name="Interpreter signature (optional)"
        type="Interpreter"
        desc="If an interpreter was required we need them to confirm the following: 
		I have interpreted the information contained in this App to the patient to the 
		best of my ability and in a way in which I believe he or she can understand. 
		Please use the SIGN button below to create a signature or sign in the box using 
		your mouse (desktop) or finger (tablet/mobile) to confirm this."
      />
    </Box>
  );
};

export default Signatures;
