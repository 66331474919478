import React, { ReactNode } from "react";

import useProcedureProvider from './hooks';
import { ProcedureProviderContext } from "./procedure.context";

interface Props {
  procedureId?: string;
	children: ReactNode;
}

//TODO use React.memo to only re-render provider if patientId changes

const ProcedureProvider = ({ procedureId, children }: Props) => {
  
  const { procedure, procedureProviderDispatch } = useProcedureProvider(procedureId);

  return (
      <ProcedureProviderContext.Provider value={{ procedure, procedureProviderDispatch }}>
        {children}
      </ProcedureProviderContext.Provider>
	  );
}

export default ProcedureProvider;