import { useContext, useMemo, useState } from "react"

import { QualityAssurance } from "../../../../models";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { Severity } from "../../../../common/enums/severity.enum";
import { FetchStatus } from "../../../../common/enums/fetch-status.enum";
import { apiInstance } from "../../../../utils/api";

export default function useQualityAssuranceItem(qualityAssurance: QualityAssurance) {

  const [status, setStatus] = useState<FetchStatus>();

  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useContext(APINotificationContext);

  const answer = useMemo(() => consentForm.answers.find(answer => answer.qualityAssuranceId === qualityAssurance.id), [consentForm.answers, qualityAssurance.id])

  const disable = (consentForm.patientSubmittedAt !== null && consentForm.patientSubmittedAt !== "") || status === FetchStatus.fetching;

  async function handleRadioChange(event: any) {

    const {value} = event.target;
    try {
    setStatus(FetchStatus.fetching);

    answer
    ? await updateAnswer(JSON.parse(value))
    : await createAnswer(JSON.parse(value))
      const statusRes = await apiInstance.get(`/consent-forms/${consentForm.id}?fields=status`);

      if(statusRes.status === 200 && statusRes.data.status !== consentForm.status) {
        dispatch({type: ConsentFormProviderActionType.UPDATE_CONSENT_FORM, payload: { status: statusRes.data.status }});
      }

      setStatus(FetchStatus.success);
    } catch (err) {
      setStatus(FetchStatus.error);
      console.log(err);
      dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: {message: "Error trying to getting application status", severity: Severity.ERROR} });
    }
  }

  async function createAnswer(value: boolean) {

    try {
      const data = {
        understood: value,
        consentFormId: consentForm.id,
        qualityAssuranceId: qualityAssurance.id
      };

      const res = await apiInstance.post(`/answers`, data);
      if (res.status === 201) {
        dispatch({ type: ConsentFormProviderActionType.ADD_ANSWER, payload: res.data})
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: {message: "Error trying to create answer", severity: Severity.ERROR} });
    }
  }

  async function updateAnswer(newValue: boolean) {

    try {
      if(!answer) throw Error("Can no update answer: Answer is undefined.");

      const data = {
        understood: newValue
      };

      const res = await apiInstance.patch(`/answers/${answer.id}`, data);

      if (res.status === 200) {
        dispatch({ type: ConsentFormProviderActionType.UPDATE_ANSWER, payload: res.data });
      }

    } catch (err) {
      console.log(err);
      dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: {message: "Error trying to update answer", severity: Severity.ERROR} });
    }
  }

  return {
    answer,
    disable,
    handleRadioChange,
    status
  }
}
