import { FormEvent, useContext, useState } from "react"
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";
import { Interaction } from "../../../../models/interaction.model";
import { apiInstance } from "../../../../utils/api";
import { GlobalStateContext } from "../../../app/context/global-state-context";

interface Props {
  answerId: string;
  addInteraction: (interaction: Interaction) => void;
  hideForm: () => void;
}

export default function useInteractionForm({addInteraction, answerId, hideForm}: Props) {

  const { state } = useContext(GlobalStateContext);
  const { dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useContext(APINotificationContext);
  const [ comment, setComment ] = useState<string>("");

  function handleChange (event: any) {

    const {value} = event.target;
    
    setComment(value);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {

    event.preventDefault();
    try{

      const data = {
        answerId,
        patientComment: comment,
        patientId: state.patient.id
      }
  
      const res = await apiInstance.post(`/interactions`, data);
  
      if(res.status === 201){
          
        addInteraction(res.data);
        dispatch({ type: ConsentFormProviderActionType.ADD_INTERACTION, payload: res.data });
        hideForm();
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION,  payload: { message: "Error trying to send question", severity: Severity.ERROR } })
    }
  }

	return {
    comment,
    handleChange,
    handleSubmit,
    state,
	}
}