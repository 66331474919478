import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useQuestionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: "1px solid lightgray",
      backgroundColor: "#eeee",
      padding: 15
    },
    divider:{
      width: "100%",
      marginBottom: 20
    },
    listItem: {
      flexDirection: "column"
    },
    questionsTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    questionsSubTitle: {
      fontSize: '16px',
    }
  }),
);