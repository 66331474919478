import { Container, Typography } from "@material-ui/core";
import React from "react";
import LoggedIn from "../templates/logged-in";
import { ConsentApplications } from "./consent-applications";

const Home = () => {
	
	return (
		<LoggedIn>
			<Container>
				<Typography variant="h1" component="h1">Welcome to the Medical Consenting App</Typography>
				<Typography variant="body1">
					This app is designed to help you make some important decisions by highlighting the potential
					risks and benefits of your procedure.
				</Typography>
				<ConsentApplications />
			</Container>
		</LoggedIn>
	);
}

export default Home;