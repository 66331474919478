import { Hidden } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import useFetchConsentApplications from "./hooks/fetchConsentApplications";

import { DesktopList, MobileList } from ".";



const ConsentApplications = () => {
	const { fetchConsentForms, consentApps } = useFetchConsentApplications();

	useEffect(() => {
		fetchConsentForms();
	}, [fetchConsentForms]);

	return (
		<Fragment>
			<Hidden smDown>
				<DesktopList consentApps={consentApps} />
			</Hidden>

			<Hidden mdUp>
				<MobileList consentApps={consentApps} />
			</Hidden>
		</Fragment>
	);
}

export default ConsentApplications;