import { Procedure } from "./procedure.model";
import { initialSpeciality, Speciality } from "./speciality.model";
import { User } from "./user.model";

export interface SubSpeciality {
	id: string;
  name: string;
  specialityId: string;

  //relations  
  Speciality: Speciality;
  users: User[];
  procedures: Procedure[];
}

export const initialSubSpeciality: SubSpeciality = {
	id: "",
  name: "",
  specialityId: "",

  //relations  
  Speciality: initialSpeciality,
  users: [],
  procedures: [],
}