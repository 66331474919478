import { ChangeEvent, useState } from "react";
import { apiInstance } from "../../../utils/api";

export default function useResetPassword() {
  const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [error, setError] = useState<null | string>(null);
	const [values, setValues] = useState({ username: "" })

	function onFieldChange(event: ChangeEvent<any>) {
		const { name, value }: { name: string, value: string } = event.target
		setValues(values => ({ ...values, [name]: value }));
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		setError(null);

		const { username } = values;

		try {
			const res = await apiInstance.post("/auth/forgot-password", { username, type: "patient" });
			if (res.status === 200 || res.status === 201) {
				setSuccess(true);
			}
		} catch (error) {
			console.log(error);
			// Handle errors
			switch (error.response.data.message) {
				case "User not found":
					setError("We could not find a user account associated to this email address.");
					break;
				case "Invalid CSRF token":
					setError("Expired token. Please refresh the page and try again.");
					break;
				default:
					setError("Unexpected server error. Please try again.");
			}
		} finally {
			setLoading(false);
		}
  }
  
  return {
    error,
    handleSubmit,
    loading,
    onFieldChange,
    success,
    values
  }
}