import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import queryString from "query-string";

import { apiInstance } from "../../../utils/api";
import { APINotificationContext } from "../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentForm } from "../../../models";

export default function useGettingStarted() {

  const { dispatchNotification } = useContext(APINotificationContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [missingParams, setMissingParams] = useState<boolean>(false);
  const [invalidParams, setInvalidParams] = useState<boolean>(false);
	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [countDown, setCountDown] = useState(0);
	const [consentForm, setConsentForm] = useState<ConsentForm | null>(null);
  const [setPassword, setSetPassword] = useState<boolean>(false);


  const location = useLocation();
  const params = queryString.parse(location.search);

  const sessionToken = params.sessionToken as string;
  const userId = params.uid as string;
  const consentFormId = params.consentForm as string;

  const fetchConsentForm = useCallback(async () => {
		
		try {
			setConsentForm(null);
			setLoading(true);

			const qb = RequestQueryBuilder.create();
			qb.select(["id"])
				.setJoin({ field: "clinic", select: ["name", "logoId"] })
				.setJoin({ field: "user", select: ["title", "firstName", "lastName", "avatar"] })
				.setJoin({ field: "user.resources", select: ["location", "type"] })
				.setJoin({ field: "patient", select: ["id", "email"] })
				.setJoin({ field: "procedure", select: ["id", "name"] })
				.query();

			const res = await apiInstance.get(`/consent-forms/${consentFormId}?${qb.queryString}`);
			
			if (res.data) {
				setConsentForm(res.data);
			}
		} catch (error) {
      setCountDown(30);
			console.log(error);
		} finally {
			setLoading(false);
		}
	}, [consentFormId]);

  const createSessionReq = useCallback(async () => {
		try {
			setLoading(true);
			const res = await apiInstance.post(`/auth/create-session`, {
				sessionToken,
				userId
			});

			if (res.data) {
				setSuccess(res.data.valid);
				if (res.data.valid) {
					sessionStorage.setItem("auth", new Date().getTime().toString());
          await fetchConsentForm();
          if(res.data.passwordSetted){
            setSetPassword(true);
          }
				}
			}
		} catch (error) {      
      setCountDown(30);
      setInvalidParams(true);
		} finally {
			setLoading(false);
		}
	}, [fetchConsentForm, sessionToken, userId]);

  useEffect(() => {
    
    if(!sessionToken || !userId || !consentFormId){
      setCountDown(30);
      setMissingParams(true);
    } else {
      createSessionReq();
    }        

  }, [createSessionReq, fetchConsentForm, sessionToken, userId, consentFormId])

  useEffect(() => {

    if(missingParams || invalidParams){
      if(countDown > 0){ 
        const countDownTO = setTimeout(() =>{
          setCountDown(countDown - 1);
        }, 1000);
        return () => {
          clearTimeout(countDownTO);
        }
      } else {
        setRedirectToLogin(true);
      }
    }
    
  }, [countDown, invalidParams, missingParams]);	

	return {
    consentForm,
    countDown,
		createSessionReq,
    invalidParams,
    missingParams,
    redirectToLogin,
    setPassword,
		success,
		loading
	}
}