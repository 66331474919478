import { Fade, TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";

const Loading = () => {
	return (
		<Fragment>
			<Fade in={true} timeout={1500}>
				<TableRow>
					<TableCell colSpan={5}><Skeleton animation="wave" width="100%" height={35} /></TableCell>
				</TableRow>
			</Fade>
			<Fade in={true} timeout={2000}>
				<TableRow>
					<TableCell colSpan={5}><Skeleton animation="wave" width="100%" height={35} /></TableCell>
				</TableRow>
			</Fade>
			<Fade in={true} timeout={2500}>
				<TableRow>
					<TableCell colSpan={5}><Skeleton animation="wave" width="100%" height={35} /></TableCell>
				</TableRow>
			</Fade>
		</Fragment>
	);
}

export default Loading;