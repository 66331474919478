import { QuestionTypes } from "../common/enums/question-types.enum";
import { Answer } from "./answer.model";

export interface Question {
  id: string;
  title: string;
  description: string;
  options: string;
  type: QuestionTypes;
  value: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  riskId: string | null;
  considerationId: string | null;
  benefitId: string | null;

  //relations
  answers: Answer[];
}

export const initialQuestion: Question = {
  id: "",
  title: "",
  description: "",
  options: "",
  type: QuestionTypes.FREE_TEXT,
  value: "",
  createdBy: "",
  createdAt: "",
  updatedBy: "",
  updatedAt: "",
  riskId: null,
  considerationId: null,
  benefitId: null,
  answers: []
}