import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { FormEvent, useContext, useEffect, useMemo, useState } from "react";

import { Severity } from "../../../common/enums/severity.enum";
import { TopicType } from "../../../common/enums/topic-type.enum";
import { APINotificationContext } from "../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ProcedureProviderActionType } from "../../../common/providers/procedure/enums/procedure-provider-action-type.enum";
import { ProcedureProviderContext } from "../../../common/providers/procedure/procedure.context";
import { Question } from "../../../models";
import { apiInstance } from "../../../utils/api";

export default function useQuestions(topicId: string, topicType: TopicType) {
    
    const { dispatchNotification } = useContext(APINotificationContext);
    const { procedure, procedureProviderDispatch } = useContext(ProcedureProviderContext);
    
    const [ questions, setQuestions] = useState<Question[]>([]);
    const [ showForm, setShowForm] = useState<boolean>(false);

    const cachedQuestions = useMemo (() =>{

      switch (topicType) {
        case TopicType.CONSIDERATIONS:

          const consideration = procedure.considerations.find(consideration => consideration.id === topicId);
          return  consideration ? consideration.questions : null;

        case TopicType.BENEFITS:

          const benefit = procedure.benefits.find(benefit => benefit.id === topicId);
          return  benefit ? benefit.questions : null;
      
        default:
          const risk = procedure.risks.find(risk => risk.id === topicId);
          return  risk ? risk.questions : null;
      }
    }, [procedure, topicId, topicType]);

    useEffect(() => {

      const fetchQuestions = async (topicId: string) => {

        try{
          const qb = RequestQueryBuilder.create();          
          qb.setOr([{ field: "considerationId", operator: "$eq", value: topicId }, { field: "benefitId", operator: "$eq", value: topicId }, { field: "riskId", operator: "$eq", value: topicId }])
            .sortBy({field: "createdAt", order: "DESC"})
            .query();
              
          const res = await apiInstance.get(`/questions?${qb.queryString}`);

          if(res.status === 200){
              setQuestions(res.data);
              procedureProviderDispatch({ type: ProcedureProviderActionType.SET_QUESTIONS, payload:{ topicId: topicId, topicType: topicType, questions: res.data }})
          }
        } catch(err) {

          console.log(err);
          dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get notifications", severity: Severity.ERROR } })
        }      
      }

      cachedQuestions ? setQuestions(cachedQuestions) : fetchQuestions(topicId);

    }, [cachedQuestions, topicId, dispatchNotification, procedureProviderDispatch]);

    async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {

      event.preventDefault();
      try{

        switch (topicType) {
          case TopicType.CONSIDERATIONS:
            data.considerationId = topicId;
            break;
  
          case TopicType.BENEFITS:
            data.benefitId = topicId;
            break;
        
          default:
            data.riskId = topicId;
        }

        if(data.options){
          data.options = data.options.split(',').map((option: string) => option.trim()).join(',');
        }
    
        const res = await apiInstance.post(`/questions`, data);
        if(res.status === 201){
          setQuestions(questions.concat(res.data));
          dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "The question has been saved successfully", severity: Severity.SUCCESS }});
        }
      } catch(err) {
  
        console.log(err);
        dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to save the question", severity: Severity.ERROR }});
      } finally {
        toggleShowForm();
      }
    }

    function toggleShowForm() {

      setShowForm(!showForm);
    } 

	return {
    handleSubmit,
    questions,
    showForm,
    toggleShowForm,
	}
}