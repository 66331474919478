import { Answer } from "./answer.model";
import { Question } from "./question.model";

export interface Consideration {
	id: string,
	name: string;
  content: string;
  understandText: string;
  notUnderstandText: string;
  order: number;
  createdAt: string;
  updatedAt: string;

	// Relations	
	answers: Answer[]; //TODO this is not the DB model a consideration in the frontend may have only 1 Answer. We need to change this.
  questions?: Question[];
}

export const initialConsideration: Consideration = {
	id: "",
	name: "",
  content: "",
  understandText: "",
  notUnderstandText: "",
  order:0,
  createdAt: "",
  updatedAt: "",

	// Relations	
	answers: []
}