import React from "react";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { useInteractions } from "./hooks";

import InteractionItem from "./interaction-item/interaction-item";
import InteractionForm from "./interaction-form";
import clsx from "clsx";

interface Props {
  answerId: string;
  canAddNew: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: {
      minWidth: "35px",
    },
    blueBox: {
      border: "1px solid #97c6ff",
      backgroundColor: "#f4f9ff",
    },
    reviewClick: {
      cursor: "pointer",
    },
    section: {
      padding: 15,
    },
    sectionTitle: {
      paddingTop: 15,
      marginBottom: 0,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: "0.8rem",
    },
  })
);

const Interactions = ({ answerId, canAddNew }: Props) => {
  const css = useStyles();

  const {
    addInteraction,
    consultantAnswered,
    interactions,
    setAnswerAsUnderstood,
    showInteractionForm,
    toggleShowInteractionForm,
  } = useInteractions(answerId, canAddNew);
  
  return (
    <Box
      component="section"
      className={clsx(css.section, interactions.length > 0 && css.blueBox)}
      flex={1}
    >
      {interactions.length > 0 ? (
        <Typography
          variant="h6"
          component="p"
          className={css.sectionTitle}
          style={{ fontSize: 16 }}
        >
          Your questions:
        </Typography>
      ) : null}
      {interactions.map((interaction, index) => (
        <InteractionItem key={index} interaction={interaction} />
      ))}
      {canAddNew && showInteractionForm ? (
        <InteractionForm
          answerId={answerId}
          addInteraction={addInteraction}
          hideForm={toggleShowInteractionForm}
          canCancel={interactions.length > 0}
        />
      ) : null}
      {canAddNew && consultantAnswered && !showInteractionForm ? (
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleShowInteractionForm}
          >
            New Question
          </Button>
          <Button
            style={{ marginLeft: "15px" }}
            variant="outlined"
            color="secondary"
            onClick={() => setAnswerAsUnderstood()}
          >
            I understand
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default Interactions;
