import { useState } from "react";

export default function usePendingQueriesModal() {
  
  const [ open, setOpen ] = useState(true);

  function handleClose(){
    
    setOpen(false);
  }

  return {
    handleClose,
    open
  }
}