import { useContext, useEffect, useState } from "react";
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { apiInstance } from "../../../../utils/api";
import { GlobalStateContext } from "../../../app/context/global-state-context";
import { ActionTypes } from "../../../app/enums/action-type.enum";


export default function useLoggedIn() {

  const { dispatchNotification } = useContext(APINotificationContext);
  const { state, dispatch } = useContext(GlobalStateContext);  
  const [showSessionExpires, setShowSessionExpires] = useState<boolean>(false);
  const [countDown, setCountDown] = useState(0);
  	
	useEffect(() => {
    
    if(process.env.REACT_APP_SESSION_TIME){

      const sessionTO = setTimeout(() =>{
        setShowSessionExpires(true);
        setCountDown(120);
      }, parseInt(process.env.REACT_APP_SESSION_TIME));

      return () => {
        clearTimeout(sessionTO);
      }
    }
  });

  useEffect(() => {
    
    if(countDown > 0){
      const countDownTO = setTimeout(() =>{
        setCountDown(countDown - 1);
      }, 1000);
      return () => {
        clearTimeout(countDownTO);
      }
    }
  }, [countDown]);

  // Fetch logged in user
	useEffect(() => {
		const getUser = async () => {
			try {
				const res = await apiInstance.get("/me");
				dispatch({ type: ActionTypes.SET_PATIENT, payload: res.data });
			} catch (error) {
				console.log(error);
			}
		}

		if (sessionStorage.getItem("auth") && state.patient.id === "") {
			getUser();
		}
	}, [dispatch, state.patient]);

  async function handleClose () {
    try {
      const res = await apiInstance.get("");
      console.log(res);
    } catch (error) {
      
      dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: error.response.data.message || "Error trying to renew your session", severity: Severity.ERROR } });
    } finally {
      setShowSessionExpires(false);
    }
  };
 
  return {
    countDown,
    state,
    handleClose,
    showSessionExpires,
  }
}