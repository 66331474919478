import { useCallback, useState } from "react";
import { ConsentForm } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

export default function useFetchConsentApplications() {
	const [loading, setLoading] = useState<boolean>(false);
	const [consentApps, setConsentApps] = useState<ConsentForm[] | null>(null);

	const fetchConsentForms = useCallback(async () => {
		try {
			setConsentApps(null);
			setLoading(true);

			const res = await apiInstance.get(`/me/consent-forms`);

			const res2 = await apiInstance.get(`/quality-assurances`);

			if (res.data) {
				if(res.data.length > 0){
					res.data.forEach((application: any) => {
						application.qualityAssurances = res2.data;
					});

				}				
				setConsentApps(res.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}, []);

	return {
		fetchConsentForms,
		consentApps,
		loading
	}
}