import React from "react";
import { Box, createStyles, FormControlLabel, makeStyles, Radio, RadioGroup, Theme } from "@material-ui/core";
import clsx from "clsx";
import { Answer } from "../../../models";
import { TopicType } from "../../../common/enums/topic-type.enum";
import useAnswerForm from "./hooks";
import Interactions from "../../Interactions";

interface Props {
	answer: Answer | undefined;
	notUnderstandText?: string;
	topicId: string;
	topicType: TopicType;
	understandText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			marginTop: 25
		},
		label: {
			background: theme.palette.grey[200],
			margin: "5px 0",
			display: "flex",
			alignItems: "center",
			padding: 5,
			"& span": {
				marginBottom: 0
			},
			border: "1px solid #e6e6e6"
		},
		understand: {
			background: "#ebf7d9",
			borderColor: "#9bd796"
		},
		notUnderstand: {
			background: "#feebda",
			borderColor: "#ffc98b"
		},
		active: {}
	}),
);

const AnswerForm = ({ answer, notUnderstandText, topicId, topicType, understandText, }: Props) => {
	
	const css = useStyles();
  const { answerId, disable, handleChange, showInteractions, value } = useAnswerForm({ answer, topicId, topicType });

	return (
		<Box mt={3} >
			<RadioGroup aria-required aria-label="answer" name="answer" value={value} onChange={handleChange}>
				<FormControlLabel
					disabled={disable}
					className={clsx(css.label, css.understand, value === "understood" && css.active)}
					value="understood"
					control={<Radio required style={{ color: "#424242" }} />}
					label={understandText || "I understand"}
				/>

				<FormControlLabel
					disabled={disable}
					className={clsx(css.label, css.notUnderstand, value === "not-understand" && css.active)}
					value="not-understood"
					control={<Radio required style={{ color: "#424242" }} />}
					label={notUnderstandText || "I have a query."}
				/>
			</RadioGroup>

			{
				answerId && showInteractions()
				? (
					<Interactions answerId={answerId} canAddNew={value !== "understood"} />
				)
				: null				
			}
		</Box>
	);
}

export default AnswerForm;