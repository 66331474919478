import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useQuestionItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    options: {
      flexDirection: 'row'
    },
    questionTitle: {
      fontWeight: 'bold',
      fontSize: '18px'
    },
    questionDesc: {
      fontSize: '16px'
    }
  }),
);