import { Clinic, initialClinic } from "./clinic.model";
import { Interaction } from "./interaction.model";
import { Procedure } from "./procedure.model";
import { Resource } from "./resource.model";
import { initialSpeciality, Speciality } from "./speciality.model";
import { initialSubSpeciality, SubSpeciality } from "./sub-speciality.model";

export interface User {
	id: string;
	title: string;
	firstName: string;
	lastName: string;
	sex: string;
	dateOfBirth: string;
	phone: string;
	email: string;
	jobTitle: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
  specialityId: string;
  subSpecialityId: string;
  clinicId: string;

  //relations
  speciality: Speciality;
  subSpeciality: SubSpeciality;
  clinic: Clinic;
  procedures: Procedure[];
  resources: Resource[];
  interactions: Interaction[];
}

export const initialUser: User = {
	id: "",
	title: "",
	firstName: "",
	lastName: "",
	sex: "",
	dateOfBirth: "",
	phone: "",
	email: "",
	jobTitle: "",
  avatar: "",
  createdAt: "",
  updatedAt: "",
  specialityId: "",
  subSpecialityId: "",
  clinicId: "",

  //relations
  speciality: initialSpeciality,
  subSpeciality: initialSubSpeciality,
  clinic: initialClinic,
  procedures: [],
  resources: [],
  interactions: [],
}

