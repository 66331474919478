import { Box, Button, Container, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import LoggedIn from "../templates/logged-in";
import useThankYou from "./hooks";

const useStyles = makeStyles((theme: Theme) => ({
	img: {
		marginLeft: 100,
	},
	[theme.breakpoints.down('md')]: {
		contentbody: {
			flexDirection: "column"
		},
		img: {
			marginLeft: 0,
			marginTop: 25,
			maxWidth: "100%"
		}
	},
}));

const ThankYou = () => {
	const css = useStyles();
	const { logout } = useThankYou();

	return (
		<LoggedIn title="Process Complete">
			<Container>
				<Typography variant="h1" component="h1">Thank you</Typography>
				<Box display="flex" component="article" className={css.contentbody}>
					<Box component="section">
						<Typography variant="body1">
							Thank you for completing the online consent app and submitting this to your consultant.
						</Typography>
						<Typography variant="body1">
							You will be invited to meet with your Healthcare team to resolve any concerns you have
							raised as a result of completing this online process before being asked to provide final
							consent to proceed. This will give you another opportunity to ask any further questions
							that may have arisen.
						</Typography>

						<Divider style={{ margin: "25px 0" }} />

						<Typography variant="body2" style={{ marginBottom: 25 }}>
							To protect your personal data please remember to now log out by clicking on the button
							below if you are finished.
						</Typography>

						<Button
							variant="contained"
							color="primary"
							onClick={logout}>
							Log out
						</Button>
					</Box>
					<img src="/images/consent-meeting.jpg" alt="Consent Meeting" width={500} className={css.img} />
				</Box>
			</Container>
		</LoggedIn>
	);
};

export default ThankYou;
