import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useContext, useEffect, useState } from "react";
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { initialUser, User } from "../../../../models";
import { Interaction } from "../../../../models/interaction.model";
import { apiInstance } from "../../../../utils/api";

export default function useInteractionItem(interaction: Interaction) {
  
  const { dispatchNotification } = useContext(APINotificationContext);

  const [ user, setUser ] = useState<User>(initialUser);  

  useEffect(() => {

    const fetchUser = async () => {
      try{
        const qb = RequestQueryBuilder.create();
        qb.select([ "id", "title", "firstName", "lastName", "avatar" ])
        const res = await apiInstance.get(`/users/${interaction.userId}`);                    
        
        if(res.status === 200){
          setUser(res.data);
        }
      } catch(err) {

        console.log(err);
        dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get user", severity: Severity.ERROR } })
      }
    }
    if(interaction.userId) {
      
      fetchUser();
    }
  }, [interaction.userId, dispatchNotification]);

	return {
    user,
	}
}