import { RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

import { Boolean } from "../../../../common/enums/boolean.enum";
import { ConsentFormStatus } from "../../../../common/enums/consent-form-status.enum";
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";
import { ProcedureProviderContext } from "../../../../common/providers/procedure/procedure.context";
import { Resource } from "../../../../models";
import { apiInstance } from "../../../../utils/api";
import { GlobalStateContext } from "../../../app/context/global-state-context";

export default function useSignature(
  type: string,
  existingSignature: undefined | Resource
) {
  const { state } = useContext(GlobalStateContext);
  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { procedure } = useContext(ProcedureProviderContext);
  const { dispatchNotification } = useContext(APINotificationContext);

  const [edition, setEdition] = useState<Boolean>(Boolean.FALSE);
  const [loading, setLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<Resource | undefined>();
  const [generatedSignature, setGeneratedSignature] = useState("");
  const [signed, setSigned] = useState<boolean>(false);
  const [meta, setMeta] = useState<{ [key: string]: string }>({
    fullName: "",
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
    entity: "signature",
    type,
  }); // if we don't initialize the meta we get an error on console as we use meta to control the form field.

  const anyModified = useMemo(() => {
    for (const answer of consentForm.answers) {
      const topic = answer.benefitId
        ? procedure.benefits.find((benefit) => benefit.id === answer.benefitId)
        : answer.considerationId
        ? procedure.considerations.find(
            (consideration) => consideration.id === answer.considerationId
          )
        : procedure.risks.find((risk) => risk.id === answer.riskId);

      if (topic && new Date(answer.updatedAt) < new Date(topic.updatedAt)) {
        return true;
      }
    }
    return false;
  }, [consentForm.answers, procedure]);

  const sigCanvas = useRef<any>(null);

  const incomingMeta = existingSignature ? existingSignature.meta : "";
  const locked = consentForm.locked;
  const submitted =
    consentForm.patientSubmittedAt !== null &&
    consentForm.patientSubmittedAt !== "";

  useEffect(() => {
    if (existingSignature) {
      setSignature(existingSignature);
    }
  }, [existingSignature]);

  useEffect(() => {
    if (incomingMeta !== "") {
      setMeta({
        ...JSON.parse(incomingMeta),
        type,
      });
    } else {
      if (type === "Patient") {
        setMeta({
          fullName: `${state.patient.firstName} ${state.patient.lastName}`,
          address1: state.patient.addressLine1,
          address2: state.patient.addressLine2,
          city: state.patient.city,
          county: state.patient.county,
          postcode: state.patient.postcode,
          entity: "signature",
          type,
        });
      }
    }
  }, [
    incomingMeta,
    type,
    state.patient.firstName,
    state.patient.lastName,
    state.patient.addressLine1,
    state.patient.addressLine2,
    state.patient.city,
    state.patient.county,
    state.patient.postcode,
  ]); // A big dependency array but this avoid unnecessary re-renders due to pass referenced objects because we all only passing primitives.

  // Disable canvas while loading
  useEffect(() => {
    if (loading && sigCanvas.current) {
      sigCanvas.current.off();
    }
  }, [loading]);

  const clear = () => {
    setSigned(false);
    if (generatedSignature) {
      setGeneratedSignature("");
    } else if (sigCanvas) {
      sigCanvas.current.clear();
    }
  };

  const resetMeta = () => {
    if (existingSignature) {
      const metaParsed = JSON.parse(existingSignature.meta);
      setMeta({
        ...meta,
        fullName: metaParsed.fullName,
        address1: metaParsed.address1,
        address2: metaParsed.address2,
        city: metaParsed.city,
        county: metaParsed.county,
        postcode: metaParsed.postcode,
      });
    }
  };

  const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setMeta({ ...meta, [name]: value });
  };

  function createGeneratedSignature() {
    setGeneratedSignature(meta["fullName"]);
    setSigned(true);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (generatedSignature) {
      var node: any = document.getElementById("generatedSignature");
      await htmlToImage.toBlob(node).then(function (blob: any) {
        saveSig(blob);
      });
    } else {
      sigCanvas.current.getCanvas().toBlob(async (blob: any) => {
        saveSig(blob);
      }, "image/svg+xml");
    }
  };

  const saveSig = async (blob: any) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", blob, `signature-${type}.svg`);
      formData.append("type", "signature");
      formData.append("folder", "signatures");
      formData.append("meta", JSON.stringify(meta));
      formData.append("consentFormId", consentForm.id);

      if (type === "Patient") {
        formData.append("patientId", state.patient.id);
      }

      const res = editing()
        ? await apiInstance.patch(`/resources/${signature!.id}`, formData)
        : await apiInstance.post("/resources", formData);

      if (res.status === 201 || res.status === 200) {
        if (res.status === 201 && type === "Patient") {
          const statusRes = await apiInstance.get(
            `/consent-forms/${consentForm.id}?fields=status`
          );

          if (
            statusRes.status === 200 &&
            statusRes.data.status !== consentForm.status
          ) {
            dispatch({
              type: ConsentFormProviderActionType.UPDATE_CONSENT_FORM,
              payload: { status: statusRes.data.status },
            });
          }
        }
        getSignature(res.data.id);
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionType.SET_NOTIFICATION,
        payload: {
          message:
            "Failed to save signature, please try again. If this issue persists please contact support.",
          severity: Severity.ERROR,
        },
      });
    } finally {
      if (editing()) {
        toggleEdition();
      }
    }
    // }, "image/svg+xml");
  };

  function disable(): boolean {
    switch (type) {
      case "Patient":
        return (
          consentForm.status !== ConsentFormStatus.PENDING_PATIENT_SIGNATURE ||
          (signature && !editing()) ||
          submitted ||
          anyModified
        );

      case "Witness":
      case "Interpreter":
        return (signature && !editing()) || submitted || anyModified;

      default:
        return true;
    }
  }

  const editing = () => (edition === Boolean.FALSE ? false : true);

  const toggleEdition = () => {
    if (editing()) {
      setEdition(Boolean.FALSE);
    } else {
      setEdition(Boolean.TRUE);
    }
  };

  const getSignature = async (resourceId: string) => {
    setLoading(true);
    try {
      const qb = RequestQueryBuilder.create();
      qb.select(["id", "location", "createdAt", "meta"]).query();

      const res = await apiInstance.get(
        `/resources/${resourceId}?${qb.queryString}`
      );

      if (res.status === 200) {
        setSignature(res.data);
        return res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    clear,
    disable,
    editing,
    handleSubmit,
    loading,
    locked,
    meta,
    onFieldChange,
    resetMeta,
    saveSig,
    setSigned,
    sigCanvas,
    signature,
    generatedSignature,
    createGeneratedSignature,
    signed,
    submitted,
    toggleEdition,
  };
}
