import { Procedure } from ".";

export interface ProcedureCategory {
	id: string;
	createdAt: string;
	createdBy: string;
	name: string;
	updatedAt: string;
	updatedBy: string;

	// relations
	procedures: Procedure[];
}

export const initialProcedureCategory: ProcedureCategory = {
	id: "",
	createdAt: "",
	createdBy: "",
	name: "",
	updatedAt: "",
	updatedBy: "",

	// relations
	procedures: [],
}