import { useCallback, useContext, useEffect, useState } from "react"

import { QualityAssurance } from "../../../../models";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { Severity } from "../../../../common/enums/severity.enum";
import { apiInstance } from "../../../../utils/api";

export default function useQualityAssurances() {

  const { dispatchNotification } = useContext(APINotificationContext);
  const [qualityAssurances, setQualityAssurances] = useState<QualityAssurance[]>([]);

  const fetchQualityAssurances = useCallback(async () =>{

    try{

      const res = await apiInstance.get(`/quality-assurances`);

      if(res.status === 200){
        setQualityAssurances(res.data);
      }
    } catch (err){
      dispatchNotification({type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get Quality Assurances", severity: Severity.ERROR }})
    }
  },[dispatchNotification]);

  useEffect(() => {
    
    fetchQualityAssurances();    
  }, [fetchQualityAssurances]);

  return {
    qualityAssurances
  }
}