import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import { Loading, Status } from ".";
import { ConsentForm } from "../../../models";
import { Link as RouterLink } from "react-router-dom";
import useConsentApplications from "./hooks/useConsentApplications";

interface Props {
	consentApps: ConsentForm[] | null;
}

const DesktopList = ({ consentApps }: Props) => {
	const { getContinueLink } = useConsentApplications();
	return (
		<TableContainer>
			<Typography variant="body1">Please see below a summary of your procedures.</Typography>
			<Table size="small" style={{ marginTop: 50 }}>
				<TableHead>
					<TableRow>
						<TableCell>Procedure</TableCell>
						<TableCell>Created At</TableCell>
						<TableCell>Clinic</TableCell>
						<TableCell>Primary Consultant</TableCell>
						<TableCell>Status</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{consentApps ? consentApps.map(app => {						
						const finished = app.patientSubmittedAt;

						return (
							<TableRow key={app.id}>
								<TableCell component="th" scope="row">
									{app.procedure!.name}
								</TableCell>
								<TableCell>{format(new Date(app.createdAt), "dd/MM/yyyy, HH:mm")}</TableCell>
								<TableCell>{app.clinic!.name}</TableCell>
								<TableCell>{app.user?.title} {app.user?.firstName} {app.user?.lastName}</TableCell>
								<TableCell>
									<Status text={app.status} />
								</TableCell>
								<TableCell>
									{finished ? (
										<Button component={RouterLink} to={`/consenting-process/${app.id}/summary`} variant="contained" color="primary" >
											View Summary
										</Button>
									) : (
											<Button component={RouterLink} to={getContinueLink(app)} variant="contained" color="primary" >
												Continue Application
											</Button>
										)}
								</TableCell>
							</TableRow>
						);
					}) : <Loading />}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default DesktopList;