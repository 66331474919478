import React, { Fragment, ReactNode } from "react";
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { EmailOutlined } from "@material-ui/icons";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import useLoggedIn from './hooks';
import Profile from "../../Profile";
import Copyright from "../copyright";

interface Props {
	children: ReactNode;
	title?: string;
	applyOffset?: boolean; 
	// All other props
	[x: string]: any;
}

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(2),
	},
	appBar: {
		background: theme.palette.grey[200],
		boxShadow: "none",
		padding: "25px 0"
	},
	logo: {
		borderRadius: 2
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
	footer: {
		padding: theme.spacing(1, 2),			
		backgroundColor: theme.palette.grey[200],
		position: 'absolute',
		width: '100%',
		bottom: 0,
		zIndex: 9999
	},
	footerTitle:{
		margin: 0,
		fontSize: '0.7rem'
	},
	footerText: {
		fontSize: '0.6rem'
	},
	contentContainer: {
		[theme.breakpoints.down("sm")]: {
			paddingTop: 20,
			paddingBottom: 20
		}
	},
	overflow: {
		minHeight: 152
	},
	offset: theme.mixins.toolbar
}));

const LoggedIn = ({ applyOffset, children, title }: Props) => {
	const classes = useStyles();
	const { countDown, handleClose, showSessionExpires } = useLoggedIn();

	if (!sessionStorage.getItem("auth")) {
		return <Redirect to="/login" />;
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={showSessionExpires}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle disableTypography id="alert-dialog-title">
					<Typography component="h2" variant="h6">Are you there?</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Your session will expire in {`${ Math.floor((countDown % (60 * 60)) / 60)}:${ Math.floor((countDown % 60)).toLocaleString('en-GB', {minimumIntegerDigits: 2})}`}. Do you want to stay signed in?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" color="default">
						Yes, I'm here
					</Button>
				</DialogActions>
			</Dialog>
			<Helmet>
				<title>{title ? `${title} | Medical Consent` : "Medical Consent"} </title>
			</Helmet>

			<AppBar position="relative" color="transparent" className={classes.appBar}>
				<Toolbar variant="dense">
					<Container maxWidth="lg">
						<Grid container justify="space-between" alignItems="center">
							<img className={classes.logo} src="/images/Medical_Consent_Logo.svg" width="225px" alt="Medical Consent" />

							<Box>
								<Box mt={1} display="flex" alignItems="center" justifyContent="flex-end">
									<EmailOutlined color="primary" style={{ marginRight: 10 }} />
									<Typography variant="h6" color="inherit" noWrap style={{ margin: 0 }}>
										support@medicalconsent.co.uk
									</Typography>
								</Box>

							</Box>
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>

			<Box className={classes.contentContainer} paddingTop={5} paddingBottom={5} boxSizing="border-box" component="main">
				<Container maxWidth="lg">
					<Profile />
					<Fragment>{children}</Fragment>
				</Container>
			</Box>

			<footer className={classes.footer}>
			<Typography className={classes.footerTitle} variant="h6" align="center" gutterBottom>
					Medical Consent Ltd
				</Typography>
				<Typography className={classes.footerText} variant="subtitle1" align="center" color="textSecondary" component="address">
					Registered in England and Wales, Company Number: 12892046, VAT Number: 373 7494 59,
					Address: 11 Leadenhall Street,
					Fifth Floor,
					London,
					EC3V 1LP
				</Typography>
				<Copyright />
				{applyOffset ? <div className={classes.offset} /> : null}
			</footer>
			{applyOffset ? <div className={classes.offset} /> : null}
			<div className={classes.overflow}/>
		</div>
	);
}

export default LoggedIn;
