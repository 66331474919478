export interface Patient {
	id: string;
	title: string;
	firstName: string;
	lastName: string;
	dob: string;
	insuranceAuthorisationCode: string;
	NHSnumber: string;
	privateInsuranceNumber: string;
	phone: string;
	email: string;
	createdAt: string;
	updatedAt: string;
	sex: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	county: string;
	postcode: string;
}

export const initialPatient: Patient = {
	id: "",
	title: "",
	firstName: "",
	lastName: "",
	dob: "",
	insuranceAuthorisationCode: "",
	NHSnumber: "",
	privateInsuranceNumber: "",
	phone: "",
	email: "",
	createdAt: "",
	updatedAt: "",
	sex: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	postcode: "",
}