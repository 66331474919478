import { useContext } from "react";
import { ConsentFormContext } from "../../../common/providers/consent-form/consent-form.context";

export default function useConsentingProcess() {

	const { consentForm } = useContext(ConsentFormContext);  

	return {
    consentForm
	}
}