import { useContext, useState } from "react";
import { initialPatient } from "../../../models";
import { apiInstance } from "../../../utils/api";
import { GlobalStateContext } from "../../app/context/global-state-context";
import { ActionTypes } from "../../app/enums/action-type.enum";

export default function useThankYou() {
  const { dispatch } = useContext(GlobalStateContext);
  const [finished, setFinished] = useState<boolean>(false);

  async function logout() {
    try {
      await apiInstance.get("/auth/logout");
      sessionStorage.removeItem("auth");
      setFinished(true);
      dispatch({ type: ActionTypes.SET_PATIENT, payload: initialPatient });
    } catch (error) {
      console.log(error);
    }
  }

  return {
    finished,
    logout
  }
}
