import React from "react";

import { Alert, Skeleton } from "@material-ui/lab";

import { Box, Divider, Fade, Grid, List, ListItem, makeStyles, Paper, Typography } from "@material-ui/core";
import useQualityAssurances from "./hooks";
import { QualityAssurance } from "../../../models";
import QualityAssuranceItem from "../quality-assuranceItem";

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%'
  },
  infoText: {
		fontSize: '1rem',
		marginTop: '0'
	},
  important: {
    fontSize: "1.25rem",
    fontWeight: 500
  },
  importantText: {
    fontSize: '1rem',
    marginTop: '15px'
  },
  section: {
		display: "block",
		padding: 0,
		marginBottom: 25,
		marginTop: 25,
		paddingBottom: 25
	},  
}));

const QualityAssurances = () => {
  const css = useStyles();

  const { qualityAssurances } = useQualityAssurances();

  if (!qualityAssurances) {
    return (
        <Paper elevation={1} component="section">
            <Fade in={true} timeout={1500}>
                <div style={{ padding: 15 }}>
                    <Skeleton animation="wave" width={300} />
                    <Box display="flex">
                        <Skeleton animation="wave" style={{ marginRight: 5 }} width={100} />
                        <Skeleton animation="wave" width={50} />
                    </Box>
                    <Divider light style={{ margin: "15px 0" }} />
                    <Skeleton animation="wave" width={365} />
                    <Box display="flex">
                        <Skeleton animation="wave" style={{ marginRight: 5 }} width={180} />
                        <Skeleton animation="wave" width={180} />
                    </Box>
                    <br />
                    <Skeleton animation="wave" width={365} />
                    <Box display="flex">
                        <Skeleton animation="wave" style={{ marginRight: 5 }} width={180} />
                        <Skeleton animation="wave" width={180} />
                    </Box>
                    <br />
                    <Skeleton animation="wave" width={365} />
                    <Skeleton animation="wave" width={365} />
                </div>
            </Fade>
        </Paper>
    )
  }

  return (
    <Box component="section" mb={5}>
      <Typography component="h2" variant="h2">Consent</Typography>
      <Alert className={css.alert} severity="warning" >
        <Typography variant="h2" className={css.important}>Important</Typography>
        <Typography variant="body2" className={css.importantText}>It is important before you sign to reflect for a moment on whether this consent process has satisfied several key criteria for you to make an informed decision. You will remember at the start of the App we went through what informed consent is all about and what is expected of you and your surgeon. The following check list is to help you with that moment of reflection:</Typography>
      </Alert>
      <Grid container >
        <Grid item xs={12}>
          <List>
            {qualityAssurances.map((qualityAssurance: QualityAssurance) => <QualityAssuranceItem key={qualityAssurance.id} qualityAssurance={qualityAssurance} /> )}
            <ListItem className={css.section}>
              <Alert className={css.alert} severity="info" >
                <Typography variant="body2" className={css.infoText}>
                  All answers must be "Yes" before you can continue the process. If you have answered "No" to any of the above questions, we ask that you go back to each topic to review the content again and ask your health professional additional questions.
                </Typography>
              </Alert>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QualityAssurances;