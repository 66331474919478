import { Answer } from "./answer.model";
import { Question } from "./question.model";

export interface Benefit {
  id: string;
  name: string;  
  content: string;
  order: number;
  createdAt: string;
  updatedAt: string;

  //relations  
  // procedures: Procedure[];
  answer?: Answer;
  questions?: Question[];
}

export const initialBenefit: Benefit = {
  id: "",
  name: "",
  content: "",
  order: 0,
  createdAt: "",
  updatedAt: "",

  //relations  
  // procedures: [],
  // answers: [],
}