import { Box, Fade } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const Loading = () => {
	return (
		<Fade in={true} timeout={1500}>
			<Box mt={5} display="flex">
				<Box component="aside" mr={8} flexBasis={0} position="relative" width="200px">
					<Skeleton variant="circle" width={100} height={100} />
				</Box>

				<Box flex="1">
					<Skeleton variant="text" width="60%" height={100} />
					<Skeleton variant="rect" width="100%" height={300} />
				</Box>
			</Box>
		</Fade>
	);
}

export default Loading;