import React from "react";
import { makeStyles, createStyles, Theme, withStyles, Tooltip, Button, ClickAwayListener } from "@material-ui/core";
import { frequencyMapping } from ".";

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		button: {
			textDecoration: "underline",
			textTransform: "initial",
			color: "#1717e6",
			padding: 1,
			minWidth: "unset"
		}
	})
});

/*
	Custom ToolTip
*/
const HtmlTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: '#6c6d6e',
		color: '#fff',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(14),
		border: 'none',
		fontWeight: "normal",
		padding: 15
	},
}))(Tooltip);

interface Props {
	frequency: any;
}

const RiskFrequency = ({ frequency }: Props) => {
	const css = useStyles();
	const freq = frequencyMapping[frequency];

	return (
		<HtmlTooltip
			disableFocusListener
			enterTouchDelay={0}
			placement="top"
			arrow
			title={
				<div>
					<p style={{margin: 0}}>
						<sup>*</sup>Effecting {freq.value} patients. eg {freq.verbal}.
					</p>
				</div>
			}>
			<Button className={css.button}>{freq.text}<sup>*</sup></Button>
		</HtmlTooltip>
	);
}

export default RiskFrequency;