import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		notice: {
			fontSize: 12,
			background: "red",
			color: "white",
			padding: "0 5px",
			position: "fixed",
			right: 0,
			top: 0,
			margin: 2,
			zIndex: 9999
		}
	}),
);

const EnvNotice = () => {
	const css = useStyles();

	return <Typography className={css.notice}>You are currently in the <strong>{process.env.REACT_APP_STAGE || "undefined"}</strong> environment</Typography>
}

export default EnvNotice;