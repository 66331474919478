import { makeStyles } from "@material-ui/core";

export const useLoggedOutStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(2),
	},
	appBar: {
		background: theme.palette.grey[200],
		boxShadow: "none",
		padding: "25px 0"
	},
	logo: {
		borderRadius: 2
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
  footer: {
    padding: theme.spacing(2, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[200],
    position: 'absolute',
    width: '100%',
    bottom: 0,
    zIndex: 9999
  },
	footerOffset: {
		minHeight: 152
	},
	contentContainer: {
		[theme.breakpoints.down("sm")]: {
			paddingTop: 20,
			paddingBottom: 20
		}
	},
	offset: theme.mixins.toolbar,
}));