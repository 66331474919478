import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useCallback, useContext, useEffect, useState } from "react";
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { Resource } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

export default function useSignatures() {

	const { dispatchNotification } = useContext(APINotificationContext);
	const { consentForm } = useContext(ConsentFormContext);

	const [signatures, setSignatures] = useState<Resource[]>([]);

	useEffect(() =>{

		const fetchSignatures = async () =>{
			try {
				const qb = RequestQueryBuilder.create();
				qb.select(["id", "location", "createdAt", "meta"])
					.setFilter({field: "consentFormId", operator: "$eq", value: consentForm.id})
					.setFilter({field: "type", operator: "$eq", value: "signature"})
					.query();
	
				const res = await apiInstance.get(`/resources?${qb.queryString}`);
	
				if (res.status === 200) {
					setSignatures(res.data);
					return res.data;
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get signatures" , severity: Severity.ERROR } });
			}
		}

		if(consentForm.id !== ""){
			fetchSignatures();
		}
		
	}, [consentForm.id, dispatchNotification]);

	const getSignatureByType = useCallback((type: string) => {
		
		const signature = signatures.find(s => {				
			const meta = JSON.parse(s.meta);
			if (meta.type === type) {
				return true;
			}
			return false;
		});

		return signature;		
	},[signatures])
	
	return {
		getSignatureByType,
	}
}