import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import { ConsentFormStatus } from "../../../../common/enums/consent-form-status.enum";

import { Severity } from "../../../../common/enums/severity.enum";
import { TopicType } from "../../../../common/enums/topic-type.enum";
import { useQuery } from "../../../../common/hooks/use-query";
import { Topic } from "../../../../common/interafaces";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";
import { ProcedureProviderContext } from "../../../../common/providers/procedure/procedure.context"
import { Answer, initialBenefit, initialConsideration, initialRisk, RiskFrequency } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

export default function useConsentTopic(type: TopicType) {

	const { dispatchNotification } = useContext(APINotificationContext);
	const { procedure } = useContext(ProcedureProviderContext);
	const { consentForm, dispatch } = useContext(ConsentFormContext);

	const query = useQuery();
	const history = useHistory();
		
	const [ answer, setAnswer ] = useState<Answer>();
	const [ canContinue, setCanContinue ] = useState(false);
	const [ disableForm, setDisableForm ] = useState(false);
	const [ modified, setModified ] = useState(false);
	const [ riskFrequency, setRiskFrequency ] = useState<RiskFrequency>();
	const [ topic, setTopic ] = useState<Topic>(initialBenefit);	

	const item = parseInt(query.get("item") || "0");

	const subProceduresText = consentForm.subProceduresText;
	const subProcedures = consentForm.subProcedures;

	const html = topic.content.replace(
		"{frequency}",
		`<span id="frequency-${topic.id}"></span>` // Container the Frequency component renders into
	);

	// Calculate next and prev links
	const { next, prev } = useMemo(() => {

		const baseContentPath = `/consenting-process/${consentForm.id}`;
		
		if(consentForm.status !== ConsentFormStatus.NOT_STARTED && consentForm.status !== ConsentFormStatus.IN_PROGRESS){
			return { next: `${baseContentPath}/summary`, prev: `${baseContentPath}/summary`}
		}
		
		let next = "";
		let prev = "";

		const benefits = procedure.benefits.length;
		const considerations = procedure.considerations.length;
		const risks = procedure.risks.length;
		
		switch (type) {
			case TopicType.CONSIDERATIONS:
				
				next = item < considerations-1 
					? `${baseContentPath}/considerations?item=${item+1}`
					: `${baseContentPath}/benefits`
						
				prev = item > 0
					? `${baseContentPath}/considerations?item=${item-1}`
					: `/`
				
				break;

			case TopicType.BENEFITS:

				next = item < benefits-1 
					? `${baseContentPath}/benefits?item=${item+1}`
					: `${baseContentPath}/risks`
						
				prev = item > 0
					? `${baseContentPath}/benefits?item=${item-1}`
					: `${baseContentPath}/considerations?item=${considerations-1}`
				break;

			case TopicType.RISKS:

				next = item < risks-1 
					? `${baseContentPath}/risks?item=${item+1}`
					: `${baseContentPath}/summary`
						
				prev = item > 0
					? `${baseContentPath}/risks?item=${item-1}`
					: `${baseContentPath}/benefits?item=${benefits-1}`
					break;
			
			default:
				break;
		}

		return { next, prev}
	}, [consentForm.id, consentForm.status, item, procedure, type]);

	// Set topic
	useEffect(() => {

		//TODO discuss with luke to swap the actual path consenting-process/:consentFormId/considerations?item=x to consenting-process/:consentFormId/considerations/:considerationId in this way we can get the topic directly form API instead to get all inside procedure.
		switch (type) {
			case TopicType.CONSIDERATIONS:

				setTopic( procedure.considerations.sort((a, b) => a.order - b.order)[item] || initialConsideration );
				break;

			case TopicType.BENEFITS:

				setTopic( procedure.benefits.sort((a, b) => a.order - b.order)[item] || initialBenefit );
				break;

			case TopicType.RISKS:

					setTopic( procedure.risks.sort((a, b) => a.order - b.order)[item] || initialRisk );
					break;
			
			default:
				break;
		}
	}, [item, procedure, type]);

	// fetch riskfrequency
	useEffect(() => {

		const fetchRiskFrequency = async () => {
			 try {
					const qb = RequestQueryBuilder.create();										
					qb.setFilter({ field: "procedureId", operator: "$eq", value: procedure.id })
						.setFilter({ field: "riskId", operator: "$eq", value: topic.id })
						.query();

			const res = await apiInstance.get(`/risk-frequencies?${qb.queryString}`);

					if (res.status === 200 && res.data.length === 1) {
						setRiskFrequency(res.data[0]);
					}
			 } catch (err) {
				 console.log(err);
				 dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get answer", severity: Severity.ERROR } });
			 }
		}

		if(procedure.id !== "" && topic.id !== "" && type === TopicType.RISKS){
			
			fetchRiskFrequency();
		}		
	}, [dispatchNotification, procedure.id, topic.id, type]);

	// fetch answer
	useEffect(() => {

		const fetchAnswer = async () => {
			try {
				const qb = RequestQueryBuilder.create();
				qb.setJoin({ field: "interactions" })
				qb.search({
					$and:[ 
						{consentFormId: consentForm.id},
						{ $or: [
								{considerationId: topic.id},
								{benefitId: topic.id},
								{riskId: topic.id}
							] 
						}
					] 
				})
				.query();

				const res = await apiInstance.get(`/answers?${qb.queryString}`);
				
				if (res.status === 200) {
					
					setAnswer(res.data[0]);
				}
			} catch (err) {
				console.log(err);
				dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get answer", severity: Severity.ERROR } });
			}
		}

		if(consentForm.id !== "" && topic.id !== ""){
			const answer = consentForm.answers.find(answer => answer.considerationId === topic.id || answer.benefitId  === topic.id || answer.riskId  === topic.id)

			answer ? setAnswer(answer) : fetchAnswer();
			
		}		
	}, [consentForm.answers, consentForm.id, dispatchNotification, topic.id]);

	// check if modified
	useEffect(() => {

		answer && !consentForm.locked && new Date(answer.updatedAt) < new Date(topic.updatedAt)
		?	setModified(true)
		:	setModified(false);
	}, [answer, consentForm.locked, topic.updatedAt]);

	// 
	useEffect(() => {

		if(consentForm.patientSubmittedAt !== null && !modified){

			setDisableForm(true);
		} else {

			setDisableForm(false);
		}

	}, [modified, consentForm.patientSubmittedAt]);

	useEffect(() => {
		
		const answer = consentForm.answers.find(answer => answer.benefitId === topic.id || answer.considerationId === topic.id || answer.riskId === topic.id );
		
		const allQuestionsAnswered = topic.questions
			? topic.questions.reduce((value, currentQuestion) => {
					return consentForm.answers.find(answer => answer.questionId === currentQuestion.id) ? value : false;
				}, true) 
			: true;

		answer && (answer.understood || (answer.interactions && answer.interactions.length > 0)) && allQuestionsAnswered && !modified
		?	setCanContinue(true)
		: setCanContinue(false);		

	},[consentForm.answers, modified, topic.id, topic.questions]);

	async function updateAnswer() {
		
		if(answer){

			try{

				const res = await apiInstance.patch(`/answers/${answer.id}`, {updatedAt: new Date()} );
				if(res.status === 200){
					
					dispatch({ type: ConsentFormProviderActionType.UPDATE_ANSWER, payload: res.data });
					(consentForm.status !== ConsentFormStatus.NOT_STARTED && consentForm.status !== ConsentFormStatus.IN_PROGRESS) ? history.push(`/consenting-process/${consentForm.id}/summary`) : history.push(next);
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	return {
		answer,
		canContinue,
		disableForm,
		html,
		modified,
		next,
		prev,
		procedure,
		riskFrequency,
		subProcedures,
		subProceduresText,
		topic,
		updateAnswer,
	}
}
