import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { Question } from "../../../models";

import QuestionForm from "../question-form";

import useQuestionItem from "./hooks";
import { useQuestionItemStyles } from "./question-item.css";

/* 
	Individual Risk
*/
interface iProps {
	canBeEdited?: boolean;
	question: Question;
}

const QuestionItem = ({ question }: iProps) => {

	const classes = useQuestionItemStyles();
	const { answer } = useQuestionItem({question});

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="h4" className={classes.questionTitle}>{question.title}</Typography>
				<Typography variant="caption" className={classes.questionDesc}>{question.description}</Typography>
			</Grid>
			<Grid item xs={12}>
				<QuestionForm answer={answer} options={question.options ? question.options.split(',') : undefined} questionId={question.id} type={question.type}  />
			</Grid>
		</Grid>
	);
}

export default QuestionItem;