import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useContext, useEffect, useState } from "react";
import { Severity } from "../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../common/providers/consent-form/consent-form.context";
import { ConsentFormProviderActionType } from "../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";
import { Interaction } from "../../../models/interaction.model";
import { apiInstance } from "../../../utils/api";

export default function useInteractions(answerId: string, canAddNew: boolean) {

  const { dispatchNotification } = useContext(APINotificationContext);
  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const [ interactions, setInteractions] = useState<Interaction[]>([])  
  const [ showInteractionForm, setShowInteractionForm ] = useState(false);

  useEffect(() => {

    // reset state variables
    setShowInteractionForm(false);
    setInteractions([]);

    const fetchInteractions = async (answerId: string) => {

      try{
        const qb = RequestQueryBuilder.create();
        qb.select([ "id", "patientComment", "userComment", "createdBy", "createdAt", "updatedBy", "updatedAt", "answerId", "patientId", "userId" ])
          .setFilter({ field: "answerId", operator: "$eq", value: answerId })
          .sortBy({field: "createdAt", order: "ASC"})
          .query();
            
        const res = await apiInstance.get(`/interactions?${qb.queryString}`);

        if(res.status === 200){
          if(res.data.length > 0){
            setInteractions(res.data);
            dispatch({ type: ConsentFormProviderActionType.SET_INTERACTIONS, payload: { answerId, interactions: res.data } });
          } 
          if(res.data.length === 0 && canAddNew){
            setShowInteractionForm(true);
          }
        }
      } catch(err) {

        console.log(err);
        dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get notifications", severity: Severity.ERROR } })
      }      
    }

    const answer = consentForm.answers.find( answer => answer.id === answerId );
    answer && answer.interactions ? setInteractions(answer.interactions) : fetchInteractions(answerId);

  }, [answerId, canAddNew, consentForm.answers, dispatch, dispatchNotification]);

  function addInteraction(interaction: Interaction){
    setInteractions(interactions.concat(interaction));
  }

  const consultantAnswered = interactions.length > 0 && interactions[interactions.length - 1].userComment !== null

  async function setAnswerAsUnderstood(){

    try {
      const data = {
        understood: true
      };

      const res = await apiInstance.patch(`/answers/${answerId}`, data);
      
      if(res.status === 200){
        dispatch({type: ConsentFormProviderActionType.UPDATE_ANSWER, payload: res.data});
        
        const statusRes = await apiInstance.get(`/consent-forms/${consentForm.id}?fields=status`);

        if(statusRes.status === 200 && statusRes.data.status !== consentForm.status) {
          dispatch({type: ConsentFormProviderActionType.UPDATE_CONSENT_FORM, payload: { status: statusRes.data.status }});
        }
      }
    } catch (err) {

      console.log(err);
      dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to update answer", severity: Severity.ERROR } });
    }
    
  }

  function toggleShowInteractionForm(){

    setShowInteractionForm(!showInteractionForm);
  }

	return {
    addInteraction,
    consultantAnswered,
    interactions,
    setAnswerAsUnderstood,
    showInteractionForm,
    toggleShowInteractionForm,
	}
}