import { useContext, useMemo } from "react";
import { Severity } from "../../../../common/enums/severity.enum";
import { Topic } from "../../../../common/interafaces";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";
import { apiInstance } from "../../../../utils/api";

export default function useTopicItem(topic: Topic) {
  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useContext(APINotificationContext);

  const consentFormId = consentForm.id;

  const answer = useMemo(() => {
    return consentForm.answers.find(
      (answer) =>
        answer.benefitId === topic.id ||
        answer.riskId === topic.id ||
        answer.considerationId === topic.id
    );
  }, [consentForm.answers, topic.id]);

  // Done by jon - card no. 288 - TODO add useMemo like above but for the flags
  const flag = useMemo(() => {
    return consentForm.flags.find(
      (flag) =>
        flag.benefitId === topic.id ||
        flag.riskId === topic.id ||
        flag.considerationId === topic.id
    );
  }, [consentForm.flags, topic.id]);

  function modified() {
    return !answer
      ? false
      : !consentForm.locked &&
          new Date(answer.updatedAt) < new Date(topic.updatedAt);
  }

  const hideThisItem = useMemo(() => {
    return (
      consentForm.locked &&
      new Date(consentForm.updatedAt) < new Date(topic.createdAt)
    );
  }, [consentForm.locked, consentForm.updatedAt, topic.createdAt]);

  async function setAnswerAsUnderstood() {
    if (answer) {
      try {
        const data = {
          understood: true,
        };

        const res = await apiInstance.patch(`/answers/${answer.id}`, data);

        if (res.status === 200) {
          dispatch({
            type: ConsentFormProviderActionType.UPDATE_ANSWER,
            payload: res.data,
          });

          const statusRes = await apiInstance.get(
            `/consent-forms/${consentForm.id}?fields=status`
          );

          if (
            statusRes.status === 200 &&
            statusRes.data.status !== consentForm.status
          ) {
            dispatch({
              type: ConsentFormProviderActionType.UPDATE_CONSENT_FORM,
              payload: { status: statusRes.data.status },
            });
          }
        }
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionType.SET_NOTIFICATION,
          payload: {
            message: "Error trying to update answer",
            severity: Severity.ERROR,
          },
        });
      }
    }
  }

  function showUnderstoodButton() {
    return answer &&
      !answer.understood &&
      (!answer.interactions ||
        answer.interactions[answer.interactions.length - 1].userComment)
      ? true
      : false;
  }

  return {
    answer,
    flag,
    consentFormId,
    hideThisItem,
    modified,
    setAnswerAsUnderstood,
    showUnderstoodButton,
  };
}
