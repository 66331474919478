import { useContext, useState } from "react";
import { Severity } from "../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../common/providers/consent-form/consent-form.context";
import { ConsentFormStatus } from "../../../common/enums/consent-form-status.enum";
import { apiInstance } from "../../../utils/api";

export default function useSummary() {

	const { consentForm } = useContext(ConsentFormContext);
	const { dispatchNotification } = useContext(APINotificationContext);

	const [ loading, setLoading ] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);

	async function submit() {
		try {
			setLoading(true);
			const res = await apiInstance.patch("/consent-forms/" + consentForm.id, {
				patientSubmittedAt: new Date()
			});

			if (res.status === 200) {
				dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Your application has been submitted successfully", severity: Severity.SUCCESS } });
				goToThanks();
			}
		} catch (error) {

			console.log(error);
			dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Sorry, we have found an unexpected error trying to submit your applications", severity: Severity.ERROR } });
		} finally {

			setLoading(false);
		}
	}

	function formCanBeSubmitted() {
	
		return consentForm.status === ConsentFormStatus.PENDING_PATIENT_SUBMISSION;
	}

	function goToThanks(){
		setSuccess(true);
	}

	function showQA(){		

		switch (consentForm.status) {
			case ConsentFormStatus.NOT_STARTED:
			case ConsentFormStatus.IN_PROGRESS:
			case ConsentFormStatus.PENDING_QUERIES:

				return false;
		
			default:
				return true;
		}
	}

	function showSignatures(){
		
		switch (consentForm.status) {
			case ConsentFormStatus.NOT_STARTED:
			case ConsentFormStatus.IN_PROGRESS:
			case ConsentFormStatus.PENDING_QUERIES:
			case ConsentFormStatus.PENDING_QAS:
			case ConsentFormStatus.QA_NOT_UNDERSTOOD:

				return false;
		
			default:
				return true;
		}
	}

	async function downloadPDF(){
		
		try{
			
			const res = await apiInstance.get(`/consent-forms/${consentForm.id}/download-summary`);
			
			if(res.status === 200){
				window.open(res.data.url, '_blank');
			}
		} catch (err) {
			console.log(err);
		}
	}

	return {
		consentForm,
		downloadPDF,
		formCanBeSubmitted,
		goToThanks,
		loading,
		showQA,
		showSignatures,	
		submit,
		success,
	}
}
