
import React, { ChangeEvent, ReactNode, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Link } from "@material-ui/core";
import { apiInstance } from "../../../utils/api";

export default function useNewPassword() {
	const location = useLocation();
	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [error, setError] = useState<null | string | ReactNode>(null);
	const [values, setValues] = useState({ newPassword: "", confirmPassword: "" })

	function onFieldChange(event: ChangeEvent<any>) {
		const { name, value }: { name: string, value: string } = event.target
		setValues(values => ({ ...values, [name]: value }));
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		setError(null);

		const { newPassword, confirmPassword } = values;
		const { token, resetToken } = queryString.parse(location.search);

		try {
			// Min 6 length, 1 lowercase, 1 uppercase, 1 digit, allows special chars
			const strongPwRegex = (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/);

			if (newPassword !== confirmPassword) {
				throw new Error("Passwords do not match");
			}

			if (!newPassword.match(strongPwRegex)) {
				throw new Error("Password doesn't meet criteria");
			}

			const res = await apiInstance.patch("/auth/reset-password", { newPassword, token: token || resetToken, type: "patient" });
			if (res.status === 200 || res.status === 201) {
				setSuccess(true);
			}
		} catch (error) {
			console.log(error);
			// Handle errors
			const err = (error.response && error.response.data.message) || error.message;
			switch (err) {
				case "Passwords do not match":
					setError("Passwords do not match.");
					break;
				case "Password doesn't meet criteria":
					setError("Password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter and 1 digit.");
					break;
				case "Password reset token is invalid or has expired.":
					setError(<span>This password reset token has expired. Please request a <Link component={RouterLink} to="/reset-password">new password reset</Link>.</span>);
					break;
				case "Invalid CSRF token":
					setError("Expired token. Please refresh the page and try again.");
					break;
				default:
					setError("Unexpected server error. Please try again.");
			}
		} finally {
			setLoading(false);
		}
	}

	return {
		error,
		handleSubmit,
		loading,
		onFieldChange,
		success,
		values
	}
}