import React, { useContext } from "react";
import { Box, createStyles, Divider, List, ListItem, makeStyles, Theme, Typography } from "@material-ui/core";
import { ProcedureProviderContext } from "../../../common/providers/procedure/procedure.context";
import Loading from "../../Loading";
import { TopicType } from "../../../common/enums/topic-type.enum";
import Topics from "../topics";

const useStyles = makeStyles((theme: Theme) => createStyles({
	section: {
		display: "block",
		padding: 0,
		marginBottom: 75,
		marginTop: 25,
		paddingBottom: 25,
		"&:last-of-type": {
			marginBottom: 0
		}
	}
}));

interface Props {
  procedureId?: string;
}

const ProcedureSection = ({ procedureId }: Props) => {

  // TODO modify this to use consentForm context so if procedure exists then use it else get from API then update context.
  const { procedure } = useContext(ProcedureProviderContext);
  const css = useStyles();

	return procedure.name === "" ? <Loading /> : (
		<Box width={'100%'}>
      <Typography variant="h2" component="h2">
        {procedure.name}
      </Typography>
      <Typography component="p" variant="body1">See below a summary of what you reviewed during this consenting process.</Typography>

      <Divider style={{ margin: "25px 0" }} />
      
      <List>
        <ListItem divider={true} className={css.section}>
          <Topics type={TopicType.CONSIDERATIONS} items={procedure.considerations} />
        </ListItem>
        <ListItem divider={true} className={css.section}>
          <Topics type={TopicType.BENEFITS} items={procedure.benefits} />
        </ListItem>
        <ListItem divider={true} className={css.section}>
          <Topics type={TopicType.RISKS} items={procedure.risks} />
        </ListItem>
      </List>
    </Box>
	)
}

export default ProcedureSection;