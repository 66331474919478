import Axios, { AxiosInstance, Method } from "axios";

export const apiInstance: AxiosInstance = Axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE || "/api",
  headers: {
  "Content-Type": "application/json",
  "CSRF-Token": sessionStorage.getItem("csrf") || "",
  },
});

apiInstance.interceptors.request.use(async (request) => {

  // We need to setup csrf on post put patch and delete methods
  if(isMutableMethod(request.method)){
    const csrf = sessionStorage.getItem("csrf") || await getCSRFToken();

    request.headers["CSRF-Token"] = csrf;
  }
  
  return request;
});

apiInstance.interceptors.response.use(
  (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger

      // Timestamp last successful request for rolling session
      if (localStorage.getItem("auth")) {
          sessionStorage.setItem("auth", new Date().getTime().toString());
      }

      return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Any status codes that falls outside the range of 2xx cause this function to trigger    
    switch (error.response.status) {
    case 400:
        
      return Promise.reject(error);
    case 401:
      
      console.log("apiInstanceError");
      console.log(error.response);
      if (error.response.data.message === "Invalid CSRF token" && !originalRequest._retry) {
          
        originalRequest._retry = true;
        const csrf_token = await getCSRFToken();
        apiInstance.defaults.headers['CSRF-Token'] = csrf_token;
        return apiInstance(originalRequest);
      }
      sessionStorage.removeItem("auth");
      
      return Promise.reject(error);
    case 403:

      console.log(error.response);
      return Promise.reject(error);

    case 404:
        
      return Promise.reject(error);

    case 409:
        
      return Promise.reject(error);
    case 500:
        
      return Promise.reject(error);
    default:
        
      return Promise.reject(error);
    }
  }
);

const isMutableMethod = (requestMethod: Method | undefined) => {

  switch (requestMethod) {
    case "post":
    case "put":
    case "patch":
    case "delete":
      return true;

    default:
      return false;
  }
}

const getCSRFToken = async () => {
  
  try {
    // Fetch a CSRF token to use with this session
    const res = await apiInstance.get("/auth");

    if (res.data.csrf) {

      sessionStorage.setItem("csrf", res.data.csrf);
    }
    return res.data.csrf;
  } catch (error) {
    console.log(error);
  }
}