import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Boolean } from "../../../../common/enums/boolean.enum";
import { TopicType } from "../../../../common/enums/topic-type.enum";
import { useQuery } from "../../../../common/hooks/use-query";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { ProcedureProviderContext } from "../../../../common/providers/procedure/procedure.context";

export default function useNavigation(currentType: TopicType | "summary") {

	const query = useQuery();
	const currentItem = parseInt(query.get("item") || "0");
	
	const { consentForm } = useContext(ConsentFormContext);
	const { procedure } = useContext(ProcedureProviderContext);
	const [ enableBenefitsLink, setEnableBenefitsLink ] = useState<Boolean>(Boolean.FALSE);
	const [ enableConsiderationsLink, setEnableConsiderationsLink ] = useState<Boolean>(Boolean.FALSE);
	const [ enableRisksLink, setEnableRisksLink ] = useState<Boolean>(Boolean.FALSE);
	const [ enableSummaryLink, setEnableSummaryLink ] = useState<Boolean>(Boolean.FALSE);
	const [ modified, setModified ] = useState(false);

	const {considerations, benefits, risks} = useMemo(() =>{ 		
			
			switch (currentType) {
				case TopicType.BENEFITS:
					return {considerations: [], benefits: procedure.benefits, risks: []};

				case TopicType.CONSIDERATIONS:
					return {considerations: procedure.considerations, benefits: [], risks: []};
					
				case TopicType.RISKS:
					return {considerations: [], benefits: [], risks: procedure.risks};
					
				default:
					return {considerations: procedure.considerations, benefits: procedure.benefits, risks: procedure.risks}
			}
		
	}, [currentType, procedure.benefits, procedure.considerations, procedure.risks]);

	const {benefitsAnswers, considerationsAnswers, risksAnswers} = useMemo(() =>{ 

		let benefitsAnswers = 0;
		let considerationsAnswers = 0;
		let risksAnswers = 0;

		consentForm.answers.forEach(answer => {
			
			if(answer.benefitId) {
				benefitsAnswers++;
			}

			if(answer.considerationId) {
				considerationsAnswers++;
			}

			if(answer.riskId) {
				risksAnswers++;
			}
		});
		return {benefitsAnswers, considerationsAnswers, risksAnswers}
	}, [consentForm.answers]);

	useEffect(() =>{

		setModified(false);
	}, [consentForm.answers]);

	const enableLinks = useCallback(() => {

		const benefits = procedure.benefits.length;
		const considerations = procedure.considerations.length;
		const risks = procedure.risks.length;

		if(considerations > 0 && considerations === considerationsAnswers){			
			setEnableConsiderationsLink(Boolean.TRUE);
			setEnableBenefitsLink(Boolean.TRUE);
		}

		if(benefits > 0 && benefits === benefitsAnswers){
			setEnableRisksLink(Boolean.TRUE);
		}
		
		if(risks > 0 && risks === risksAnswers){
			setEnableSummaryLink(Boolean.TRUE);
		}
	},[benefitsAnswers, considerationsAnswers, procedure.benefits.length, procedure.considerations.length, procedure.risks.length, risksAnswers]);

	useEffect(() => {		
		enableLinks();
	}, [enableLinks]);

	function activateBenefitsLink(){
		
		return enableBenefitsLink === Boolean.TRUE && !(currentType === "summary");
	}

	function activateConsiderationsLink(){
		return enableConsiderationsLink === Boolean.TRUE && !(currentType === "summary");
	}

	function activateRisksLink(){
		
		return enableRisksLink === Boolean.TRUE && !(currentType === "summary");
	}

	function activateSummaryLink(){
	
		return enableSummaryLink === Boolean.TRUE;
	}

	function checkUpdateStatus(answerUpdatedAt: string, itemUpdatedAt: string){
		
		if(new Date(answerUpdatedAt) < new Date(itemUpdatedAt)){
			setModified(true);
			return true;
		}
		return false;
	}

	return {
		activateBenefitsLink,
		activateConsiderationsLink,
		activateRisksLink,
		activateSummaryLink,
		benefits,
		checkUpdateStatus,
		consentForm,
		considerations,
		currentItem,		
		modified,
		risks,
	}
}