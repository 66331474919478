
export interface Clinic {
  id: string;
  name: string;
  email: string;
  logoId: string;
  createdBy: string,
  createdAt: string,
  updatedBy: string,
  updatedAt: string,

  //relations
  // logo: Resource;
  // users: User[];
  // patients: Patient[];
  // riskFrequencies: RiskFrequency;
  // consentForms: ConsentForm[];
}

export const initialClinic: Clinic = {
  id: "",
  name: "",
  email: "",
  logoId: "",
  createdBy: "",
  createdAt: "",
  updatedBy: "",
  updatedAt: "",

  //relations
  // logo: initialResource,
  // users: [],
  // patients: [],
  // riskFrequencies: initialRiskFrequency,
  // consentForms: [],
}
