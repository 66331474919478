import React from "react";

import {
  Box,
  Button,
  Divider,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { NewPasswordForm } from "../new-password";

import { Loading } from ".";
import LoggedOut from "../templates/logged-out/logged-out";
import useGettingStarted from "./hooks";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 200,
    alignSelf: "flex-start",
    marginBottom: 15,
    borderRadius: 3,
  },
  clinicLogo: {
    maxWidth: "75%",
  },
}));

const GettingStarted = () => {
  const css = useStyles();
  const {
    consentForm,
    countDown,
    invalidParams,
    loading,
    missingParams,
    redirectToLogin,
    setPassword,
  } = useGettingStarted();

  if (redirectToLogin) {
    return <Redirect to="/" />;
  }

  if (missingParams || invalidParams) {
    return (
      <LoggedOut title="Getting Started">
        <Box mt={5} display="flex" component="article">
          <section>
            <Typography variant="h2">Invalid link</Typography>
            <Typography variant="body1">
              Sorry, but the link you are using to access this app{" "}
              {missingParams ? `is missing some` : `has some invalid`}{" "}
              parameters.
            </Typography>
            <Typography variant="body1">
              Please contact with your consultant to get a new link.
            </Typography>

            <Box mt={4} mb={4}>
              <Typography variant="body1" color="textSecondary">
                If you have already set a password, you can{" "}
                <Link component={RouterLink} to="/login">
                  <strong>login here</strong>
                </Link>
                . You'll be redirected in {countDown} seconds.
              </Typography>
            </Box>
          </section>
        </Box>
      </LoggedOut>
    );
  }

  if (loading) {
    return (
      <LoggedOut title="Getting Started">
        <Loading />
      </LoggedOut>
    );
  }

  if (!consentForm) {
    return (
      <LoggedOut title="Getting Started">
        <Box mt={5} display="flex" component="article">
          <section>
            <Typography variant="h2">Consent application not found</Typography>
            <Typography variant="body1">
              Sorry, but can not find your consent application into our system.
            </Typography>
            <Typography variant="body1">
              Please contact with your consultant to get a new link.
            </Typography>

            <Box mt={4} mb={4}>
              <Typography variant="body1" color="textSecondary">
                If you have already set a password, you can{" "}
                <Link component={RouterLink} to="/login">
                  <strong>login here</strong>
                </Link>
                . You'll be redirected in {countDown} seconds.
              </Typography>
            </Box>
          </section>
        </Box>
      </LoggedOut>
    );
  }

  const consultant = consentForm.user!;
  const consultantFullName = `${consultant.title} ${consultant.firstName} ${consultant.lastName}`;
  const avatar =
    consultant.resources &&
    consultant.resources.length &&
    consultant.resources.filter((r) => r.type === "avatar").length > 0
      ? consultant.resources.filter((r) => r.type === "avatar")[0].location
      : null;

  return (
    <LoggedOut title="Getting Started">
      <Box mt={5} component="article">
        {avatar && (
          <Box component="aside" mr={8} position="relative">
            <img className={css.avatar} src={avatar} alt={consultantFullName} />

            <img
              className={css.clinicLogo}
              src={
                "https://medicalconsent-public-staging.s3.eu-west-2.amazonaws.com/clinic-logos/os-logo+with+text.png"
              }
              alt={consentForm.clinic!.name}
            />
          </Box>
        )}

        <section>
          <Typography variant="body1">
            <strong>{consultantFullName}</strong> from{" "}
            <strong>{consentForm.clinic!.name}</strong> invites you to work
            through this app at your leisure. As you go through it, you can ask
            questions as well as provide essential information to your
            healthcare team.
          </Typography>
          <Typography variant="body1">
            And, once you feel ready to proceed, you can also use this app to
            give your informed consent (or approval) for your{" "}
            <strong>{consentForm.procedure?.name}</strong> procedure.
          </Typography>
        </section>

        <Divider light style={{ margin: "25px 0" }} />
        {setPassword ? (
          <section>
            <Typography variant="h2">Getting Started</Typography>
            <Typography variant="body1">
              We have detected you have already set a password.
            </Typography>
            <Typography variant="body1">
              Your username is: <strong>{consentForm.patient.email}</strong>
            </Typography>
            <Typography variant="body1">
              Please
              <Link component={RouterLink} to="/login">
                <strong> login here </strong>
              </Link>
              in order to access to your personal area and continue with the
              consenting application.
            </Typography>
          </section>
        ) : (
          <section>
            <Typography variant="h2">Getting Started</Typography>
            <Typography variant="body1">
              When you log in for the first time, and so that you can return to
              the process if you don’t complete it all in one go, we ask you to
              set a password. This will help secure your personal information.
            </Typography>

            <Box mt={4} mb={4}>
              <Typography variant="body1">
                Your username is: <strong>{consentForm.patient!.email}</strong>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                If you have already set a password, you can{" "}
                <Link component={RouterLink} to="/login">
                  <strong>login here</strong>
                </Link>
                .
              </Typography>
            </Box>
            <NewPasswordForm
              hideTitleOnSuccess
              successText={
                <Box fontSize={16} component="span">
                  Your password has been successfully set. You may now{" "}
                  <Link
                    style={{ fontSize: 16 }}
                    underline="always"
                    component={RouterLink}
                    to={`/consenting-process/${consentForm.id}/considerations`}
                    variant="body2"
                  >
                    continue the consenting process
                  </Link>
                </Box>
              }
            />
          </section>
        )}
      </Box>
    </LoggedOut>
  );
};

export default GettingStarted;
