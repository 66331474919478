import { Answer } from "./answer.model";
import { Procedure } from "./procedure.model";
import { Question } from "./question.model";
import { RiskFrequency } from "./risk-frequency.model";

export interface Risk {
	id: string;
	name: string;
	content: string;
	order: number;
	createdAt: string;
  updatedAt: string;

	//relations
	procedures: Procedure[];
	riskFrequencies: RiskFrequency[];
	answer?: Answer;
  questions?: Question[];
}

export const initialRisk: Risk = {
	id: "",
	name: "",
	content: "",
	order: 0,
	createdAt: "",
  updatedAt: "",

	//relations
	procedures: [],
	riskFrequencies: [],
}