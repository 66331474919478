import { ProcedureCategory, Risk } from ".";
import { Benefit } from "./benefit.model";
import { Consideration } from "./consideration.model";
import { initialProcedureCategory } from "./procedure-category.model";

export interface Procedure {
	id: string;
	createdAt: string;
	createdBy: string;
	name: string;
	specialityId: string;
	subSpecialityId: string;
	updatedAt: string;
	updatedBy: string;

	// Join relations
	procCategory: ProcedureCategory;
	risks: Risk[];
	benefits: Benefit[];
	considerations: Consideration[];
}

export const initialProcedure: Procedure = {
	id: "",
	createdAt: "",
	createdBy: "",
	name: "",	
	specialityId: "",
	subSpecialityId: "",
	updatedAt: "",
	updatedBy: "",
	benefits: [],
	considerations: [],
	risks: [],
  procCategory: initialProcedureCategory,
};
