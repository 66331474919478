import { useContext, useEffect, useReducer } from "react";

import { Severity } from "../../../enums/severity.enum";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { ProcedureReducer } from "../procedure.reducer";
import { APINotificationContext } from "../../api-notification/api-notification.context";
import { ProcedureProviderActionType } from "../enums/procedure-provider-action-type.enum";
import { APINotificationActionType } from "../../api-notification/enums/api-notification-action-type.enum";
import { initialProcedure } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

export default function useProcedureProvider(procedureId?: string) {
  
  const [ procedure, procedureProviderDispatch ] = useReducer(ProcedureReducer, initialProcedure);
	const { dispatchNotification } = useContext(APINotificationContext);

	useEffect(() => {
		const fetchConsentForm = async () => {

			const qb = RequestQueryBuilder.create();
      qb.select([ "id", "name", ])
          .setJoin({ field: "risks", select: ["id", "name", "content", "order", "updatedAt"],  })
          .setJoin({ field: "benefits", select: ["id", "name", "content", "order", "updatedAt"] })
          .setJoin({ field: "considerations", select: ["id", "content", "name", "notUnderstandText", "order", "updatedAt", "understandText"] })
          .setFilter({ field: "risks.archive", operator: "$eq", value: false })
          .query();
  
      try{
        const res = await apiInstance.get(`/procedures/${procedureId}?${qb.queryString}`);
	
				if (res.status === 200) {
					procedureProviderDispatch({ type: ProcedureProviderActionType.SET_PROCEDURE, payload:res.data });
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get the procedure", severity: Severity.ERROR } });
			}
    };
    
    if(procedureId && procedureId !== ""){

      fetchConsentForm();
    }
  }, [procedureId, dispatchNotification]);
  
	return {
		procedure,
		procedureProviderDispatch,
	};
}