export enum ConsentFormStatus {
  COMPLETE = "Complete",
  IN_PROGRESS = 'In Progress',
  NOT_STARTED = 'Not Started',
  PENDING_CONSULTANT_SIGNATURE = "Pending Consultant Signature",
  PENDING_CONSULTANT_SUBMISSION = "Pending Consultant Submission",
  PENDING_PATIENT_SIGNATURE = "Pending Patient Signature",
  PENDING_PATIENT_SUBMISSION = "Pending Patient Submission",
  PENDING_QAS = "Pending Quality Assurances",
  PENDING_QUERIES = "Pending Queries",
  PATIENT_NOTIFIED = "Patient Notified",
  QA_NOT_UNDERSTOOD = "QA Not Understood",
}
