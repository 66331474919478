import { Box, Button, Container, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import LoggedIn from "../templates/logged-in";
import useThankYouQueries from "./hooks";

const useStyles = makeStyles((theme: Theme) => ({
	img: {
		marginLeft: 100,
	},
	[theme.breakpoints.down('md')]: {
		contentbody: {
			flexDirection: "column"
		},
		img: {
			marginLeft: 0,
			marginTop: 25,
			maxWidth: "100%"
		}
	},
}));

const ThankYouQueries = () => {
	const css = useStyles();
	const { logout } = useThankYouQueries();

	return (
		<LoggedIn title="Process Complete">
			<Container>
				<Typography variant="h1" component="h1">Thank you</Typography>
				<Box display="flex" component="article" className={css.contentbody}>
					<Box component="section">
						<Typography variant="body1">
							Thank you for your completed online consenting application.
						</Typography>
						<Typography variant="body1">
							Your consultant will now review your submission and answer any queries you may have. You will receive a notification when your concerns have been addressed.
						</Typography>

						<Divider style={{ margin: "25px 0" }} />

						<Typography variant="body2" style={{ marginBottom: 25 }}>
							To protect your personal data please remember to now log out by clicking on the button
							below if you are finished.
						</Typography>

						<Button
							variant="contained"
							color="primary"
							onClick={logout}>
							Log out
						</Button>
					</Box>
					<img src="/images/consent-meeting.jpg" alt="Consent Meeting" width={500} className={css.img} />
				</Box>
			</Container>
		</LoggedIn>
	);
};

export default ThankYouQueries;
