import { useContext, useEffect, useReducer } from "react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { ConsentFormReducer } from "../consent-form.reducer";
import { ConsentFormProviderActionType } from "../enums/consent-form-provider-action-type.enum";
import { Severity } from "../../../enums/severity.enum";
import { APINotificationContext } from "../../api-notification/api-notification.context";
import { APINotificationActionType } from "../../api-notification/enums/api-notification-action-type.enum";
import { initialConsentForm } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

export default function useConsentFormProvider(consentFormId: string) {
  const [consentForm, dispatch] = useReducer(
    ConsentFormReducer,
    initialConsentForm
  );
  const { dispatchNotification } = useContext(APINotificationContext);

  useEffect(() => {
    const fetchConsentForm = async () => {
      try {
        const qb = RequestQueryBuilder.create();
        qb.select([
          "id",
          "subProceduresText",
          "patientSubmittedAt",
          "locked",
          "status",
          "updatedAt",
          "createdAt",
          "clinicId",
          "patientId",
          "procedureId",
          "userId",
        ])
          .setJoin({ field: "clinic", select: ["id", "name"] })
          .setJoin({ field: "patient" })
          .setJoin({ field: "user" })
          .setJoin({
            field: "answers",
            select: [
              "id",
              "understood",
              "riskId",
              "considerationId",
              "benefitId",
              "qualityAssuranceId",
              "questionId",
              "value",
              "moreDetails",
              "updatedAt",
            ],
          })
          .setJoin({
            field: "resources",
            select: [
              "id",
              "location",
              "meta",
              "patientId",
              "userId",
              "createdAt",
            ],
          })
          .setJoin({
            field: "flags",
            select: [
              "id",
              "important",
              "comment",
              "createdAt",
              "updatedAt",
              "updatedBy",
              "benefitId",
              "considerationId",
              "riskId",
              "consentFormId",
            ],
          })
          .setJoin({
            field: "subProcedures",
            select: [
              "id",
              "name"
            ],
          })
          .query();

        const res = await apiInstance.get(
          `/consent-forms/${consentFormId}?${qb.queryString}`
        );

        if (res.status === 200) {
          dispatch({
            type: ConsentFormProviderActionType.SET_CONSENT_FORM,
            payload: res.data,
          });
        }
      } catch (error) {
        console.log(error);
        dispatchNotification({
          type: APINotificationActionType.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get the consent application",
            severity: Severity.ERROR,
          },
        });
      }
    };

    fetchConsentForm();
  }, [consentFormId, dispatchNotification]);

  return {
    consentForm,
    dispatch,
  };
}
