import { Box, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { TopicType } from "../../common/enums/topic-type.enum";
import ConsentFormProvider from "../../common/providers/consent-form";
import ProcedureProvider from "../../common/providers/procedure";
import Loading from "../Loading";
import { ConsentTopic } from "./consent-topic";
import { Navigation } from "./Navigation";
import useConsentingProcess from './hooks'
import LoggedIn from "../templates/logged-in";

interface Props {
  type: TopicType;
}

const useStyles = makeStyles((theme: Theme) => ({
	[theme.breakpoints.down('md')]: {
		container: {
			flexDirection: "column"
		}
	},
}));

const ConsentingProcess = ({ type }: Props) => {
	const css = useStyles();

  const Content = () => {
    const { consentForm } = useConsentingProcess();   
    
    return consentForm.procedureId === ""
      ? <Loading/>
      : (
          <ProcedureProvider procedureId={consentForm.procedureId}>
            <Box display="flex" className={css.container}>
              <Navigation currentType={type} />
              <ConsentTopic type={type} />
            </Box>
          </ProcedureProvider>
        )
  }

	return (
    <LoggedIn title="Consenting Process">
      <ConsentFormProvider>
        <Content />
      </ConsentFormProvider>
    </LoggedIn>
	)
}

export default ConsentingProcess;