import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SignatureCanvas from "react-signature-canvas";

import { Resource } from "../../../models";
import { format } from "date-fns";
import useSignature from "./hooks";
import { RotateRightTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: "0px 16px",
    marginBottom: 6,
  },
  signature: {
    border: "1px solid grey",
    borderRadius: 2,
    width: "100%",
    margin: "5px 0 5px 0",
  },
  cancelButton: {
    marginTop: 5,
  },
  generatedSignature: {
    fontFamily: "Brush Script MT",
    fontSize: "40px",
    transform: "rotate(-1deg)",
    paddingLeft: "15px",
  },
}));

interface Props {
  name: string;
  type: "Patient" | "Witness" | "Interpreter" | "Health Professional";
  desc: string;
  existingSignature: Resource | undefined;
}

const Signature = ({ name, type, desc, existingSignature }: Props) => {
  const css = useStyles();

  const {
    clear,
    editing,
    disable,
    handleSubmit,
    loading,
    locked,
    meta,
    onFieldChange,
    resetMeta,
    setSigned,
    sigCanvas,
    signature,
    generatedSignature,
    createGeneratedSignature,
    signed,
    submitted,
    toggleEdition,
  } = useSignature(type, existingSignature);

  return (
    <Box component="section" mt={8} mb={8}>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography component="h3" variant="h3">
          <strong>{name}</strong>
        </Typography>
        <Typography component="p" variant="body1">
          {desc}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                disabled={disable()}
                size="small"
                required={signed}
                onChange={onFieldChange}
                name="fullName"
                label="Full name"
                value={meta["fullName"]}
                variant="outlined"
                inputProps={{ tabIndex: 1 }}
              />
            </FormControl>
            {signature || signed ? null : (
              <Box flex>
                <Button
                  variant="contained"
                  color="primary"
                  tabIndex={2}
                  onClick={createGeneratedSignature}
                  disabled={!meta["fullName"]}
                >
                  Sign
                </Button>
              </Box>
            )}

            {signature && !editing() ? (
              <Box>
                <img
                  className={css.signature}
                  src={signature.location}
                  alt={`${type} Signature`}
                />
                <Alert severity="success" className={css.alert}>
                  <Typography variant="caption">
                    Signed at:{" "}
                    {format(new Date(signature.createdAt), "dd/MM/yyyy, HH:mm")}
                  </Typography>
                </Alert>
                {locked || disable() ? null : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    tabIndex={3}
                    onClick={toggleEdition}
                  >
                    Edit Signature
                  </Button>
                )}
              </Box>
            ) : submitted ? null : generatedSignature ? (
              <Typography
                id="generatedSignature"
                className={css.generatedSignature}
              >
                {generatedSignature}
              </Typography>
            ) : (
              <Box style={{ display: generatedSignature ? "none" : "" }}>
                <SignatureCanvas
                  backgroundColor="#fbfbfb"
                  ref={sigCanvas}
                  canvasProps={{ className: css.signature }}
                  onEnd={(event) => setSigned(true)}
                />
              </Box>
              // </>
            )}

            {signed && !signature && (
              <Button
                disabled={loading}
                style={{ marginBottom: 12 }}
                onClick={clear}
                type="button"
                variant="outlined"
                size="small"
                tabIndex={4}
              >
                Clear Signature
              </Button>
            )}

            {(!signature || editing()) && !submitted && (
              <Button
                type="submit"
                disabled={!signed || loading || disable()}
                variant="contained"
                color="primary"
                fullWidth
                tabIndex={2}
              >
                Save Information &amp; Signature
              </Button>
            )}
            {editing() ? (
              <Button
                className={css.cancelButton}
                variant="contained"
                color="secondary"
                fullWidth
                tabIndex={2}
                onClick={() => {
                  toggleEdition();
                  resetMeta();
                }}
              >
                Cancel
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                disabled={disable()}
                size="small"
                required={signed}
                onChange={onFieldChange}
                name="address1"
                label="Address 1"
                value={meta["address1"]}
                variant="outlined"
                inputProps={{ tabIndex: 1 }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                disabled={disable()}
                size="small"
                onChange={onFieldChange}
                name="address2"
                label="Address 2"
                value={meta["address2"]}
                variant="outlined"
                inputProps={{ tabIndex: 1 }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                disabled={disable()}
                size="small"
                required={signed}
                onChange={onFieldChange}
                name="city"
                label="City"
                value={meta["city"]}
                variant="outlined"
                inputProps={{ tabIndex: 1 }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                disabled={disable()}
                size="small"
                required={signed}
                onChange={onFieldChange}
                name="county"
                label="County"
                value={meta["county"]}
                variant="outlined"
                inputProps={{ tabIndex: 1 }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                disabled={disable()}
                size="small"
                required={signed}
                onChange={onFieldChange}
                name="postcode"
                label="Postcode"
                value={meta["postcode"]}
                variant="outlined"
                inputProps={{ tabIndex: 1 }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Signature;
