import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CircularProgress, Grow } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Redirect } from 'react-router-dom';
import useFetchCSRF from '../../common/hooks/useFetchCSRF';
import useLogin from './hooks';
import LoggedOut from '../templates/logged-out/logged-out';

const useStyles = makeStyles((theme) => ({
	bg: {
		height: "100vh",
		boxSizing: "border-box",
		paddingTop: 50,
		background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
		fallbacks: {
			background: theme.palette.primary.main,
		}
	},
	paper: {
		padding: 25,
		boxSizing: "border-box",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%'
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Login = () => {
	const { attemptLogin, error, loginSuccess, loading } = useLogin();
	const classes = useStyles();
	const { CSRF } = useFetchCSRF();


	const isLoading = loading || CSRF.loading;

	if (loginSuccess && !isLoading) {
		return <Redirect to="/" />
	}

	return (
		<LoggedOut title="Sign In">
			<Container maxWidth="sm" disableGutters style={{ margin: 0 }}>
				<Typography display="block" align="left" component="h1" variant="h2">
					Medical Consent Patient Sign In
				</Typography>
				<form className={classes.form} onSubmit={attemptLogin}>
					{!CSRF.error && (
						<Fragment>
							<TextField
								type="email"
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="username"
								autoComplete="email"
								autoFocus
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={isLoading}
								className={classes.submit}>
								Login {isLoading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
							</Button>
						</Fragment>
					)}

					{CSRF.error && (
						<Grow in={true} timeout={1000}>
							<Alert style={{ margin: "15px 0" }} variant="filled" severity="error">{CSRF.error}</Alert>
						</Grow>
					)}

					{error && (
						<Grow in={true} timeout={1000}>
							<Alert style={{ margin: "15px 0" }} variant="filled" severity="error">{error}</Alert>
						</Grow>
					)}

					<Grid container>
						<Grid item xs>
							<Link component={RouterLink} to="/reset-password" variant="body2">Forgot password?</Link>
						</Grid>
					</Grid>
				</form>
			</Container>
		</LoggedOut>
		
	);
}

export default Login;