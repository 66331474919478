import { Box, Button, Card, CardActionArea, CardActions, CardContent, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Loading, Status } from ".";
import { ConsentForm } from "../../../models";
import { Link as RouterLink } from "react-router-dom";
import useConsentApplications from "./hooks/useConsentApplications";

interface Props {
	consentApps: ConsentForm[] | null;
}

const useStyles = makeStyles(() => ({
	clinic: {
		fontSize: 12
	}
}));

const MobileList = ({ consentApps }: Props) => {
	const css = useStyles();
	const { getContinueLink } = useConsentApplications();

	return (
		<Box mt={2} mb={2}>
			{consentApps ? consentApps.map(app => {
				
				const finished = app.patientSubmittedAt;

				return (
					<Box mt={2} mb={2} key={app.id}>
						<Card>
							<CardActionArea>
								<CardContent>
									<Typography gutterBottom variant="h5" component="h2">
										{app.procedure!.name}
									</Typography>
									<Typography color="textSecondary" className={css.clinic}>
										{app.clinic?.name}, {app.user?.title} {app.user?.firstName} {app.user?.lastName}
									</Typography>
									<Status text={app.status} />
								</CardContent>
							</CardActionArea>
							<CardActions>
								{finished ? (
									<Button size="small" component={RouterLink} to={`/consenting-process/${app.id}/summary`} color="primary" >
										View Summary
									</Button>
								) : (
										<Button size="small" component={RouterLink} to={getContinueLink(app)} color="primary" >
											Continue Application
										</Button>
									)}
							</CardActions>
						</Card>
					</Box>
				);
			}) : <Loading />}
		</Box>
	);
}

export default MobileList;