import { Reducer, useReducer } from "react";
import { initialPatient } from "../../../models";
import { ActionTypes } from "../enums/action-type.enum";
import { Action } from "../interfaces/action.interface";
import { GlobalState } from "../interfaces/global-state.interface";

const initialState = {
  alert: null as any,
  error: null as any,
  contentLoading: null as any,
  currentTopic: null as any,
  patient: initialPatient
}

const reducer: Reducer<GlobalState, Action> = (state, action) => {

  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_ALERT:
      return { ...state, alert: payload }

    case ActionTypes.SET_ERROR:
      return { ...state, alert: payload }

    case ActionTypes.SET_CONTENT_LOADING:
      return { ...state, contentLoading: payload }

    case ActionTypes.SET_CURRENT_TOPIC:
      return { ...state, currentTopic: payload }

    case ActionTypes.SET_PATIENT:
      return { ...state, patient: payload }

    default:
      return state;
  }
}

export default function useApp() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch
  };
}
