import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useContext, useEffect, useState } from "react"
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/api-notification/api-notification.context";
import { APINotificationActionType } from "../../../../common/providers/api-notification/enums/api-notification-action-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { Answer, Question } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

interface iState {
	title: string;
	description: string | null;
  type: string | null;
  options: string | null;
}

interface Props {
  question: Question;
}

export default function useQuestionItem({ question }: Props) {
  
  const { consentForm } = useContext(ConsentFormContext);
  const { dispatchNotification } = useContext(APINotificationContext);

  const [ answer, setAnswer ] = useState<Answer>();  

  useEffect(() => {

    const fetchAnswer = async () => {
      try {

        const qb = RequestQueryBuilder.create();
				qb.search({
					$and:[
						{consentFormId: consentForm.id},
						{questionId: question.id}
					] 
				})
				.query();

				const res = await apiInstance.get(`/answers?${qb.queryString}`);
				
				if (res.status === 200) {
					
					setAnswer(res.data[0]);
				}
      } catch (err) {

        console.log(err);
        dispatchNotification({ type: APINotificationActionType.SET_NOTIFICATION, payload: { message: "Error trying to get the answer", severity: Severity.ERROR }});  
      }
    }

    if(consentForm.id !== "" && question.id !== ""){
			const answer = consentForm.answers.find(answer => answer.questionId === question.id)

			answer ? setAnswer(answer) : fetchAnswer();
			
		}		
  }, [consentForm.id, consentForm.answers, dispatchNotification, question.id]);

	return {
    answer
	}
}