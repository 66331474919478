import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { RiskFrequency as RiskFrequencyComp } from "../RiskFrequency";
import { Alert } from "@material-ui/lab";
import { TopicType } from "../../../common/enums/topic-type.enum";
import useConsentTopic from "./hooks";
import AnswerForm from "../answer-form";
import Loading from "../../Loading";
import Questions from "../../questions";
import { useConsentTopicStyles } from "./consent-topic.css";

interface Props {
  type: TopicType;
}

const ConsentTopic = ({ type }: Props) => {
  const classes = useConsentTopicStyles();
  const {
    answer,
    canContinue,
    html,
    modified,
    next,
    prev,
    procedure,
    riskFrequency,
    subProcedures,
    subProceduresText,
    topic,
    updateAnswer,
  } = useConsentTopic(type);

  console.log(topic.name.substr(0, 13).toLowerCase());

  useEffect(() => {
    const renderRiskFreq = async () => {
      // Once the page has rendered with the container
      // insert the Frequency component in place where
      // the frequency should be within the HTML content
      const paramContainer = document.getElementById("frequency-" + topic.id);

      if (paramContainer) {
        ReactDOM.render(
          riskFrequency ? (
            <RiskFrequencyComp frequency={riskFrequency.text} />
          ) : (
            <CircularProgress size={15} color="secondary" />
          ),
          paramContainer
        );
      }
    };

    renderRiskFreq();
  }, [riskFrequency, topic.id]);

  // TODO put <Typography variant="h6" component="p" style={{fontSize: 16}}>The procedure you are consenting for is: <strong>{procedure.name}</strong></Typography> outside form this component. This displays procedure info and will be the same for all topics. This component should only display topic and children data.

  return topic.id === "" ? (
    <Loading />
  ) : (
    <Box component="section" flex={1}>
      <Typography
        variant="h6"
        component="p"
        style={{ fontSize: 16, marginBottom: 10 }}
      >
        The procedure you are consenting for is a{" "}
        <strong>{procedure.name}</strong>
        {subProcedures.length > 0 ? (
          <span>
            {" "}
            with a
            {subProcedures.map((subProcedure, i) => (
              <span>
                {" "}
                <strong>
                  {subProcedure.name}
                  {subProcedures.length > i + 1 && <span> and a</span>}
                </strong>
              </span>
            ))}
          </span>
        ) : null}
        .
      </Typography>

      {modified && (
        <Alert style={{ marginBottom: 15 }} severity="warning">
          This topic has been modified since you last reviewed it. Please review
          the topic again and confirm your answer.
        </Alert>
      )}
      <Typography component="h1" variant="h1">
        {topic.name}
      </Typography>

      <Box
        component="article"
        dangerouslySetInnerHTML={{ __html: html }}
        fontSize={16}
      />
      {topic.name.toLowerCase().includes("what happens on the day") &&
      subProceduresText !== "" ? (
        <Typography variant="body1">
          <strong>Note for sub-procedures :</strong> {subProceduresText}
        </Typography>
      ) : null}

      <Questions topicId={topic.id} topicType={type} />

      <AnswerForm
        answer={answer}
        notUnderstandText={topic.notUnderstandText}
        topicId={topic.id}
        topicType={type}
        understandText={topic.understandText}
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={5}
      >
        <Button
          component={RouterLink}
          to={prev}
          type="button"
          variant="outlined"
          color="default"
          size="large"
          disableElevation
        >
          Back
        </Button>

        {modified ? (
          <Button
            style={{ marginTop: "15px" }}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => updateAnswer()}
            disableElevation
          >
            Confirm
          </Button>
        ) : (
          <Button
            disabled={!canContinue}
            component={RouterLink}
            to={next}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
          >
            Continue{" "}
            {/*loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />*/}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ConsentTopic;
