import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { TopicType } from "../../../../common/enums/topic-type.enum";
import { ConsentFormContext } from "../../../../common/providers/consent-form/consent-form.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/consent-form/enums/consent-form-provider-action-type.enum";
import { Answer } from "../../../../models";
import { apiInstance } from "../../../../utils/api";

interface Props {
	answer: Answer | undefined;
	topicType: TopicType;
	topicId: string;
}

export default function useAnswerForm({ answer, topicId, topicType }: Props) {
  
  const { consentForm, dispatch } = useContext(ConsentFormContext);
	const [ value, setValue ] = useState<string>("");
  const [ loading, setLoading ] = useState(false);
	const [ answerId, setAnswerId ] = useState<string>();

	const disable = useMemo(() => {
		return consentForm.patientSubmittedAt !== null && consentForm.patientSubmittedAt !== "";
	}, [consentForm.patientSubmittedAt]);

  useEffect(() => {
    
		setValue(answer ? answer.understood ? "understood" : "not-understood" : "");
		setAnswerId(answer ? answer.id : "");
    
  }, [answer]);

	const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {

		const {value} = event.target;

		setValue(value);
		try {
			setLoading(true);

			const data: any = {
				understood: value === "understood" ? true : false,
				[topicType === TopicType.RISKS ? "riskId" : topicType === TopicType.BENEFITS ? "benefitId" : "considerationId"]: topicId,
				consentFormId: consentForm.id,
			}						

			const res = await apiInstance[answerId ? "patch" : "post"](answerId ? `/answers/${answerId}` : `/answers`, data);

			if (res.status === 201 || res.status === 200) {
				res.status === 201 ? dispatch({ type: ConsentFormProviderActionType.ADD_ANSWER, payload: res.data}) : dispatch({ type: ConsentFormProviderActionType.UPDATE_ANSWER, payload: res.data});
				if(!answerId){
					setAnswerId(res.data.id);
				}
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	function showInteractions(){
		return (answer && answer.interactions) || (answerId && !loading)
	}
		
	return {
		answerId,
		disable,
    handleChange,
    loading,
		showInteractions,
		value,
	}
}