import React, { Fragment, useContext } from "react";
import {
  Avatar,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Interaction } from "../../../models/interaction.model";
import { GlobalStateContext } from "../../app/context/global-state-context";
import useInteractionItem from "./hooks";

interface Props {
  interaction: Interaction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: {
      minWidth: "35px",
    },
    reviewClick: {
      cursor: "pointer",
    },
    section: {
      padding: 15,
    },
    sectionTitle: {
      paddingTop: 15,
      marginBottom: 0,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: "0.8rem",
    },
  })
);

const InteractionItem = ({ interaction }: Props) => {
  const css = useStyles();
  const { state } = useContext(GlobalStateContext);
  const { user } = useInteractionItem(interaction);

  return !interaction 
    ? null 
    : (
        <List>
          <ListItem alignItems="flex-start">
            <ListItemAvatar className={css.avatarContainer}>
              <Avatar className={css.small}>
                {state.patient.firstName.charAt(0) + state.patient.lastName.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Fragment>
                  <Typography variant="subtitle2">
                    {`${state.patient.title} ${state.patient.firstName} ${state.patient.lastName}`}:
                  </Typography>
                  <Typography variant="body2">
                    {interaction.patientComment}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          { interaction.userComment
            ? (
                <ListItem alignItems="flex-start">
                  <ListItemAvatar className={css.avatarContainer}>
                    {
                      user.avatar !== ""
                      ? (
                          <Avatar
                            alt={`${user.firstName} ${user.lastName}`}
                            src={user.avatar}
                            className={css.small}
                          />
                        )
                      : user.id !== "" 
                        ? (
                            <Avatar
                              style={{ backgroundColor: "#13b73a" }}
                              className={css.small}>
                                {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                            </Avatar>
                          )
                        : <Avatar className={css.small}>HP</Avatar>
                    }
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Fragment>
                        <Typography variant="subtitle2">
                          {
                            user.id !== "" 
                            ? `${user.title} ${user.firstName} ${user.lastName} (${user.jobTitle})`
                            : "Health Professional"
                          }
                          :
                        </Typography>
                        <Typography variant="body2">
                          {interaction.userComment}
                        </Typography>
                      </Fragment>
                    }
                  />
                  <Divider />
                </ListItem>
              ) 
            : null
          }
        </List>
  );
};

export default InteractionItem;
