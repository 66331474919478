import { Box, Divider, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { TopicType } from "../../common/enums/topic-type.enum";
import QuestionItem from "./question-item";
import useQuestions from "./hooks";
import { useQuestionsStyles } from "./questions.css";

interface iProps {
	topicId: string;
	topicType: TopicType;
}

const Questions = ({ topicId, topicType }: iProps) => {

	const classes = useQuestionsStyles();
	const { questions } = useQuestions(topicId, topicType);

	return (
    <Box mt={3} className={ questions.length > 0 ? classes.container : "" }>
			{
				questions.length > 0 
				? (<Box><Typography component="h2" className={classes.questionsTitle} >What we need to know</Typography><Typography component="p" variant="caption" className={classes.questionsSubTitle} >Please answer the questions below.</Typography></Box>)
				: null
			}
			<List>
				{
					questions.map( (question, index) => <ListItem key={question.id} className={classes.listItem}>{index > 0 ? <Divider className={classes.divider} /> : null}<QuestionItem question={question} /></ListItem>)
				}
			</List>
		</Box>
	);
}

export default Questions;