// Done by Jon - card no. 288
import React from "react";
import { Flag } from "../../../models/flag.model";

import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

const useStyles = makeStyles((theme: Theme) => ({
  flagContainer: {
    border: "solid 2px red",
    backgroundColor: "#ffb3b3",
    margin: "0 0 10px 0",
    padding: "4px 0px 0px 2px",
    flexDirection: "row",
  },
  warningIconBox: {
    padding: "0 10px",
    // vertically centers icon relative to size of flag container, which varies on length of comment(Reason) text
    minHeight: "60px",
    height: " auto",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    flagContainer: {
      flexDirection: "column",
    },
    warningIconBox: {
      padding: "0 0",
      minHeight: "0",
      alignItems: "none",
      width: "auto",
      justifyContent: "center",
    },
  },
}));

interface Props {
  flag: Flag;
}

const FlagItem = ({ flag }: Props) => {
  const css = useStyles();
  return (
    <Box display="flex" className={css.flagContainer}>
      <Box display="flex" className={css.warningIconBox}>
        <WarningRoundedIcon />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="body1">
          <strong>This has been flagged as important</strong>
        </Typography>

        <Typography variant="body1">Reason: {flag.comment}</Typography>
      </Box>
      <Box display="flex" className={css.warningIconBox}>
        <WarningRoundedIcon />
      </Box>
    </Box>
  );
};

export default FlagItem;
