import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { useFooterStyles } from "./footer.css";
import Copyright from "../copyright";

const Footer = () => {

  const classes = useFooterStyles();

	return (
    <Fragment>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Medical Consent Ltd
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="address">
          11 Leadenhall Street,
          Fifth Floor,
          London,
          EC3V 1LP
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="address">
          Registered in England and Wales, Company Number: 12892046, VAT Number: 373 7494 59
        </Typography>
        <Copyright />
      </footer>
      <div className={classes.footerOffset}/>
    </Fragment>
	);
}

export default Footer