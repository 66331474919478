import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import useApp from './hooks';
import { GlobalStateContext } from './context/global-state-context';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './app-theme';
import EnvNotice from '../env-notice';
import ScrollToTop from '../ScrollToTop';

// Routes
import NotFound from '../not-found';
import Login from '../login';
import ResetPassword from '../reset-password';
import NewPasswordPage from '../new-password/new-password-page';
import Summary from '../summary';
import { Home } from '../home';
import ThankYou from '../thank-you';
import ConsentingProcess from '../consenting-process';
import APINotificationProvider from '../../common/providers/api-notification';
import ThankYouQueries from '../thank-you-queries';
import { TopicType } from '../../common/enums/topic-type.enum';
import { GettingStarted } from '../getting-started';

function App() {
  const { state, dispatch } = useApp();

  return (
    <Router>
      <APINotificationProvider>
        <GlobalStateContext.Provider value={{ state, dispatch }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ScrollToTop />
            
            {process.env.REACT_APP_STAGE !== "production" && <EnvNotice />}
            <Switch>              
              <Route exact path="/getting-started" children={<GettingStarted />} />
              <Route exact path="/login" children={<Login />} />
              <Route exact path="/new-password" children={<NewPasswordPage />} />
              <Route exact path="/reset-password" children={<ResetPassword />} />
              
              <Route exact path="/" children={<Home />} />
              <Route path="/consenting-process/:applicationId/risks" children={<ConsentingProcess type={TopicType.RISKS} />} />
              <Route path="/consenting-process/:applicationId/benefits" children={<ConsentingProcess type={TopicType.BENEFITS} />} />
              <Route path="/consenting-process/:applicationId/considerations" children={<ConsentingProcess type={TopicType.CONSIDERATIONS} />} />
              <Route exact path="/consenting-process/:applicationId/summary" children={<Summary />} />
              <Route exact path="/consenting-process/thank-you" children={<ThankYou />} />
              <Route exact path="/consenting-process/thank-you-queries" children={<ThankYouQueries />} />
              <Route children={<NotFound />} />
            </Switch>
          </ThemeProvider>
        </GlobalStateContext.Provider>
      </APINotificationProvider>
    </Router>
  );
}

export default App;
