import { Container } from '@material-ui/core';
import React from 'react';
import LoggedOut from '../templates/logged-out/logged-out';
import Form from './form';

const NewPasswordPage = () => {
	return (
		<LoggedOut title="New Password">
			<Container>
				<Form />
			</Container>
		</LoggedOut>
	);
}

export default NewPasswordPage;