import { Reducer } from "react";
import { APINotificationActionType } from "./enums/api-notification-action-type.enum";
import { APINotification, APINotificationAction } from "./interfaces";

export const APINotificationReducer: Reducer<APINotification | null, APINotificationAction> = (notification, action) => {

	const { type, payload } = action;
    switch (type) {
        case APINotificationActionType.SET_NOTIFICATION:
                
        return payload ? payload : notification;

        case APINotificationActionType.REMOVE_NOTIFICATION:
                
        return null;
        
        default:
            return notification;
    }
}