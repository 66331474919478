import React from "react";
import { CircularProgress, FormControlLabel, ListItem, ListItemText, makeStyles, Radio, RadioGroup, Theme } from "@material-ui/core";
import { QualityAssurance } from "../../../models";
import useQualityAssuranceItem from "./hooks";
import { FetchStatus } from "../../../common/enums/fetch-status.enum";

interface Props {
  qualityAssurance: QualityAssurance;
}

const useStyles = makeStyles((theme: Theme) => ({
  radios: {
    width: 250,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  listItem: {
    backgroundColor: '#ececec',
    marginBottom: '2px',
    '& span': {
      marginBottom: 0
    }
  },
  no: {
    borderRadius: 3,
    margin: 0,
    marginLeft: 5,
    paddingRight: '15px',
    backgroundColor: '#fff1f0;',
    border: '1px solid #ffa39e'
  },
  yes: {
    borderRadius: 3,
    margin: 0,
    marginLeft: 5,
    marginRight: 5,
    paddingRight: '15px',
    backgroundColor: '#f6ffed',
    border: '1px solid #b7eb8f',
  }
}));

const QualityAssuranceItem = ({ qualityAssurance }: Props) => {

  const { answer, disable, handleRadioChange, status } = useQualityAssuranceItem(qualityAssurance);
  const css = useStyles();

  return (
    <ListItem key={qualityAssurance.id} className={css.listItem}>
      <ListItemText primary={qualityAssurance.text} />
      <RadioGroup
        className={css.radios}
        row
        value={answer ? answer.understood : null}
        onChange={handleRadioChange}>
        <FormControlLabel
          disabled={disable}
          className={css.no}
          value={false}
          control={<Radio color="primary" />}
          label="No"
        />
        <FormControlLabel
          disabled={disable}
          className={css.yes}
          value={true}
          control={<Radio color="primary" />}
          label="Yes"
        />
        {status === FetchStatus.fetching && <CircularProgress size={15} />}
      </RadioGroup>
    </ListItem>
  );
}

export default QualityAssuranceItem;
