import { Answer } from "./answer.model";
import { Clinic, initialClinic } from "./clinic.model";
import { Flag } from "./flag.model";
import { initialPatient, Patient } from "./patient.model";
import { initialProcedure, Procedure } from "./procedure.model";
import { QualityAssurance } from "./quality-assurance.model";
import { Resource } from "./resource.model";
import { initialUser, User } from "./user.model";

export interface ConsentForm {
  id: string;
  subProceduresText: string;
  locked: boolean;
  patientSubmittedAt: string;
  status: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  clinicId: string;
  procedureId: string;
  patientId: string;
  userId: string;

  // Relations
  procedure: Procedure;
  user: User;
  patient: Patient;
  clinic: Clinic;
  answers: Answer[];
  resources: Resource[];
  // Done by jon - card no. 288
  flags: Flag[];
  qualityAssurances: QualityAssurance[]; // Merged into for convenience
  subProcedures: Procedure[];
}

export const initialConsentForm: ConsentForm = {
  id: "",
  subProceduresText: "",
  locked: false,
  status: "",
  patientSubmittedAt: "",
  patientId: "",
  userId: "",
  clinicId: "",
  procedureId: "",
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
  answers: [],
  procedure: initialProcedure,
  patient: initialPatient,
  user: initialUser,
  clinic: initialClinic,
  resources: [],
  qualityAssurances: [],
  // Done by jon - card no. 288
  flags: [],
  subProcedures:[],
};
