const frequencyMapping: any = {
  "Very Rare": {
    text: "Very Rare",
    value: "Less than 1/10,000",
    verbal: "A person in a large town",
    color: "green",
  },
  Rare: {
    text: "Rare",
    value: "1/1000 to 1/10,000",
    verbal: "A person in a small town",
    color: "lightgreen",
  },
  Uncommon: {
    text: "Uncommon",
    value: "1/100 to 1/1000",
    verbal: "A person in a village",
    color: "yellow",
  },
  Common: {
    text: "Common",
    value: "1/10 to 1/100",
    verbal: "A person in a street",
    color: "orange",
  },
  "Very Common": {
    text: "Very Common",
    value: "1/10 to 1/100",
    verbal: "A person in a family",
    color: "red",
  },
  Other: {
    text: "Other",
    value: "",
    verbal: "",
    color: "grey",
  },
};

export default frequencyMapping;
