import { Box, Button, CircularProgress, List, ListItem, makeStyles, Typography, Container as MuiContainer, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";
import useSummary from "./hooks";
import QualityAssurances from "./quality-assurances";
import Signatures from "./signatures";
import { Alert } from "@material-ui/lab";
import ConsentFormProvider from "../../common/providers/consent-form/consent-form.provider";
import ProcedureSection from "./procedure-section";
import ProcedureProvider from "../../common/providers/procedure";
import { Navigation } from "../consenting-process/Navigation";
import { ConsentFormStatus } from "../../common/enums/consent-form-status.enum";
import PendingQueriesModal from "./pending-queries-modal";
import { useQuery } from "../../common/hooks/use-query";
import { GlobalStateContext } from "../app/context/global-state-context";
import LoggedIn from "../templates/logged-in";

const useStyles = makeStyles((theme) => ({
	[theme.breakpoints.down('md')]: {
		container: {
			flexDirection: "column"
		}
	},
	alert: {
		width: '100%'
	},
	downloadContainer: {
		textAlign: "right"
	},
	important: {
		fontSize: "1.25rem",
		fontWeight: 500
	},
	infoText: {
		fontSize: '1rem',
		marginTop: '0'
	},
	importantText: {
		fontSize: '1rem',
		marginTop: '15px'
	},
	section: {
		display: "block",
		padding: 0,
		marginBottom: 25,
		marginTop: 25,
		paddingBottom: 25
	},
	offset: theme.mixins.toolbar
}));

interface Params {
	showModal: string;
}


const Summary = () => {

	const css = useStyles();
	const query = useQuery();
	const showModal = query.get("showModal");

	const Form = () => {
		
		const { state } = useContext(GlobalStateContext);
		const { consentForm, downloadPDF, formCanBeSubmitted, loading, goToThanks, showQA, showSignatures, submit, success, } = useSummary();

		const isSubmitted = consentForm.patientSubmittedAt ? true : false;

		if (success) {

			return !showQA() ? <Redirect to="/consenting-process/thank-you-queries" /> : <Redirect to="/consenting-process/thank-you" />
		}

		return (			
			<Box display="flex" className={css.container}>
				{ showModal && consentForm.status === ConsentFormStatus.PENDING_QUERIES ?  <PendingQueriesModal /> : null }
				<ProcedureProvider procedureId={consentForm.procedureId}>
					<Navigation currentType="summary"/>
					<Box component="section" width="100%">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography component="h1" variant="h1">Summary of Consent</Typography>
								<Typography component="h3" variant="h3">{`for ${state.patient.title} ${state.patient.firstName} ${state.patient.lastName}, dob: ${new Date(state.patient.dob).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}`}</Typography>
							</Grid>
							<Grid item xs={6} className={css.downloadContainer}>
								{
									consentForm.locked
									? <Button variant="outlined" color="primary" onClick={downloadPDF}>Download</Button>
									: null
								}
							</Grid>
						</Grid>

						<List>
							<ListItem>
								<ProcedureSection />
							</ListItem>
							{/*TODO refactor to apply logic to show or hide QA inside QA component*/ 
								showQA()
								? (
									<ListItem divider={true} className={css.section}>
										<QualityAssurances />
									</ListItem>
								)
								: (
									<ListItem divider={true} className={css.section}>
										<Box component="section" mb={5}>
											<Typography component="h2" variant="h2">Consent</Typography>
											<Alert className={css.alert} severity="warning" >
												<Typography variant="h2" className={css.important}>Important</Typography>
												<Typography variant="body2" className={css.importantText}>
													You will be notified when your queries have been answered by your healthcare professional so that you can complete your consent.
												</Typography>
											</Alert>
										</Box>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											size="large"
											onClick={goToThanks}
											disableElevation>
											Ok, Continue
										</Button>
									</ListItem>
								)
							}
							{/*TODO refactor to apply logic to show or hide signatures inside signatures component*/ 
								showSignatures()
								? (
									<ListItem className={css.section}>
										<Signatures />
									</ListItem>
								)
								: null
							}
						</List>
						{/*TODO refactor to create a new component SummaryBar*/}
						<Box
							boxShadow={3}
							mt={5}
							padding={2}
							left={0}
							bottom={0}
							width="100%"
							style={{ background: "white", zIndex: 1 }}
							position="fixed">
							<MuiContainer maxWidth="lg">
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between">
									<Button
										component={RouterLink}
										to={!isSubmitted ? `/consenting-process/${consentForm.id}/risks` : `/`}
										type="button"
										variant="outlined"
										color="default"
										size="large"
										disableElevation>
										Back
									</Button>

									{
										formCanBeSubmitted()
										?(
											<Button
											disabled={loading}
											variant="contained"
											color="primary"
											size="large"
											onClick={submit}
											disableElevation>
												Submit {loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
											</Button>
										)
										: null
									}
								</Box>
							</MuiContainer>
						</Box>
					</Box>
				</ProcedureProvider>
			</Box>
		);
	}

	return (
		<LoggedIn applyOffset>
			<ConsentFormProvider>
					<Form />				
			</ConsentFormProvider>
		</LoggedIn>
	);
};

export default Summary;
