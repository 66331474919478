import React from "react";
import { createStyles, Link, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({		
		footerText: {
			fontSize: '0.6rem'
		}
	}),
);

const Copyright = () => {
	const classes = useStyles();
	return (
		<Typography className={classes.footerText} align="center" variant="body2" color="textSecondary">
			{'Copyright © '}
			<Link color="inherit" href="https://medicalconsent.co.uk">
				Medical Consent
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default Copyright