import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import usePendingQueriesModal from './hooks';

const PendingQueriesModal = () => {

  const { open, handleClose } = usePendingQueriesModal();

	return (
    <Dialog
			fullWidth
			maxWidth="md"
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
        <DialogTitle disableTypography id="alert-dialog-title">						
          <Typography component="h1" variant="h1">Awaiting responses</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>We are still awaiting responses to your queries... Please check back later.</Typography>
          <Typography>If you wish you can close this window to view your summary or ask additional questions.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
    </Dialog>
	);
}

export default PendingQueriesModal;