export enum ConsentFormProviderActionType {
	"ADD_ANSWER",
	"ADD_INTERACTION",
	"ADD_SIGNATURE",
	"SET_BENEFITS",
	"SET_CONSENT_FORM",
	"SET_CONSIDERATIONS",
	"SET_INTERACTIONS",
	"SET_PROCEDURE",
	"SET_QUALITY_ASSURANCES",
	"SET_RISKS",
	"UPDATE_ANSWER",
	"UPDATE_CONSENT_FORM",
	"UPDATE_INTERACTION",
	"UPDATE_SIGNATURE",
}