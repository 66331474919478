import { Box, CircularProgress, Fade } from "@material-ui/core";
import React from "react";

const Loading = () => {
	return (
		<Fade in={true} timeout={1500}>
			<Box display="flex" alignItems="center" justifyContent="center" height={350}>
				<CircularProgress size={50} color="secondary" />
			</Box>
		</Fade>
	);
}

export default Loading;