import { makeStyles } from "@material-ui/core";

export const useFooterStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[200],
    position: 'absolute',
    width: '100%',
    bottom: 0,
    zIndex: 9999
  },
	footerOffset: {
		minHeight: 152
  }
}));