import { ConsentFormStatus } from "../../../../common/enums/consent-form-status.enum";
import { Answer, ConsentForm } from "../../../../models";

export default function useConsentApplications() {	
	
	const getContinueLink = (application: ConsentForm) => {
		
		switch (application.status) {
			case ConsentFormStatus.NOT_STARTED:
				return `/consenting-process/${application.id}/considerations`;

			case ConsentFormStatus.IN_PROGRESS:
				const topicAnswers = application.answers.filter(answer => !answer.questionId);
				const lastAnswer = topicAnswers.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())[topicAnswers.length - 1]

				const topicType = lastAnswer.considerationId ? "considerations" : (lastAnswer.benefitId ? "benefits" : "risks");

				const item = topicAnswers.reduce((value: number, next: Answer) =>{

					if (lastAnswer.considerationId){
						return next.considerationId ? value + 1 : value;
					}

					if (lastAnswer.benefitId){
						return next.benefitId ? value + 1 : value;
					}

					return next.riskId ? value + 1 : value;
				}, 0);
				return `/consenting-process/${application.id}/${topicType}?item=${item-1}`;

			default:
				return `/consenting-process/${application.id}/summary?showModal=true`;
		}
	}

	return {
		getContinueLink,		
	}
}