import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button, Divider, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import useTopicItem from "./hooks";

import { TopicType } from "../../../common/enums/topic-type.enum";
import Interactions from "../../Interactions";
import { Topic } from "../../../common/interafaces";
import FlagItem from "../flags";
// import ConsentFormProvider from "../../../common/providers/consent-form/consent-form.provider";

interface Props {
  topic: Topic;
  index: number;
  type: TopicType;
}

const TopicItem = ({ index, topic, type }: Props) => {
  const {
    answer,
    flag,
    consentFormId,
    hideThisItem,
    modified,
    setAnswerAsUnderstood,
    showUnderstoodButton,
  } = useTopicItem(topic);

  if (!answer) {
    return null;
  }

  return hideThisItem ? null : (
    <Alert
      severity={
        modified() ? "error" : answer.understood ? "success" : "warning"
      }
      style={{ margin: "10px 0" }}
    >
      <AlertTitle>{topic.name}</AlertTitle>
      <Typography variant="body2">
        {modified()
          ? `Our health professionals have added new information for you to consider. In order for you to continue with your consenting application, please review this ${
              answer.riskId
                ? "risk"
                : answer.benefitId
                ? "benefit"
                : "consideration"
            } and either confirm that you understand or request further clarification using ‘ask a question’.`
          : answer.understood
          ? `You have indicated that you fully understand this ${
              answer.riskId
                ? "risk"
                : answer.benefitId
                ? "benefit"
                : "consideration"
            }`
          : `You have indicated that you do not yet fully understand this ${
              answer.riskId
                ? "risk"
                : answer.benefitId
                ? "benefit"
                : "consideration"
            }`}
      </Typography>
      {/* Done by jon - card no. 288 */}
      {flag && <FlagItem flag={flag} />}

      <Typography variant="body2">
        <Typography variant="caption">
          <Button
            component={RouterLink}
            to={`/consenting-process/${consentFormId}/${type}?item=${index}`}
            variant="outlined"
            color="primary"
            size="small"
          >
            Review
          </Button>
        </Typography>
      </Typography>

      <Divider light style={{ margin: "15px 0" }} />

      {!answer.understood ? (
        <Interactions answerId={answer.id} canAddNew={!answer.understood} />
      ) : null}
      {showUnderstoodButton() ? (
        <Button
          style={{ marginTop: "15px" }}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setAnswerAsUnderstood()}
          disableElevation
        >
          I understand
        </Button>
      ) : null}
    </Alert>
  );
};

export default TopicItem;
