import React, { useContext, useState } from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { GlobalStateContext } from "./app/context/global-state-context";
import { Redirect } from "react-router-dom";
import { ActionTypes } from "./app/enums/action-type.enum";
import { initialPatient } from "../models";
import { apiInstance } from "../utils/api";

const useStyles = makeStyles((theme) => ({
	section: {
		[theme.breakpoints.down("sm")]: {
			borderBottom: '1px solid #e2dede',
			marginBottom: 25,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}
	}
}));

const Profile = () => {
	const css = useStyles();

	const { state, dispatch } = useContext(GlobalStateContext);
	const [loggedOut, setLoggedOut] = useState<boolean>(false);

	if (!state.patient) {
		return null;
	}

	const { email } = state.patient;

	const handleLogout = async () => {
		try {
			await apiInstance.get("/auth/logout");
			sessionStorage.removeItem("auth");
			setLoggedOut(true);
			dispatch({ type: ActionTypes.SET_PATIENT, payload: initialPatient });
		} catch (error) {
			console.log(error);
		}
	}

	if (loggedOut) {
		return <Redirect to="/login" />;
	}

	return (
		<Box className={css.section} component="section" width="100%" textAlign="right">
			<Typography variant="body2" align="right" style={{ margin: 0 }}>{email}</Typography>
			<Button onClick={handleLogout} variant="text" size="small" color="primary">Log out</Button>
		</Box>
	);
};

export default Profile;