import { Box, createStyles, Hidden, Link, List, ListItem, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import { CheckCircle, ContactSupport, ErrorOutline, RadioButtonUnchecked, Warning } from "@material-ui/icons";
import React, { Fragment, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import useNavigation from "./hooks";
import { Topic } from "../../../common/interafaces";
import { TopicType } from "../../../common/enums/topic-type.enum";

interface ItemProps {
	name: string;
	link: string;
	isActive: boolean;
	index: string;
	items: Topic[];
}

interface ItemLinkProps {
	children: ReactNode,
	disabled: boolean,
	to: string
}

interface Props {
	currentType: TopicType | "summary";
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainListItem: {
			display: "block",
			padding: 0,
			marginBottom: 25,
		},
		topicLink: {
			display: "block",
			width: "100%"
		},
		topicTitle: {
			marginBottom: 0,
			display: "flex",
			alignItems: "center"
		},
		hide:{
			display: "none"
		},
		index: {
			fontSize: 12,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginLeft: 10,
			borderRadius: "50%",
			width: 20,
			height: 20,
			background: theme.palette.grey[200]
		},
		itemContainer: {
			position: "relative",
			"&::after": {
				width: 3,
				height: '100%',
				content: "''",
				display: 'block',
				background: theme.palette.grey[200],
				position: 'absolute',
				top: 20,
				right: 9,
				zIndex: -1
			},
		},
		inactive:{
			margin: 0
		},
		icon: {
			fontSize: 22,
			background: "#fafafa",
			zIndex: 1
		},
		[theme.breakpoints.down('md')]: {
			aside: {
				width: 'auto',
				marginRight: 'auto',
			},
			inactive: {
				display: "none",
			},
			index: {
				display: "none"
			},
			itemContainer: {
				"&::after": {
					display: "none"
				},
			}
		},
		listItem:{
			justifyContent: "flex-end"
		}
	}),
);

const Navigation = ({currentType}: Props) => {
	const css = useStyles();
	
	const { activateBenefitsLink, activateConsiderationsLink, activateRisksLink, activateSummaryLink, benefits, checkUpdateStatus, consentForm, considerations, currentItem, risks, modified} = useNavigation(currentType);

	const Item = ({ name, link, isActive, index, items }: ItemProps) => {

		return (
			<ListItem className={clsx(css.mainListItem, !isActive && css.inactive)}>
				<Box className={css.itemContainer} mb={0.5} width="100%" display="flex" alignItems="center" justifyContent="flex-end">
					<Typography
						component="h4"
						variant="h5"
						className={css.topicTitle}
						style={{ fontWeight: isActive ? "bold" : "normal" }}>
						{name} { (modified && isActive) ? <ErrorOutline className={css.icon} /> : <div className={css.index}>{index}</div> }
					</Typography>
				</Box>
				<Hidden mdDown>
					<List className={clsx(css.itemContainer, name === "Summary" && css.hide)}>
						{items.sort((a, b) => a.order - b.order).map((item: Topic, index: number) => {
							const active = currentItem === index && (currentType !== "summary");

							// Find any existing answers
							const answer = consentForm.answers.find(a => (a.riskId || a.benefitId || a.considerationId) === item.id);

							return (
								<ListItem key={item.id} style={{ paddingRight: 0, paddingLeft: 0 }} className={css.listItem}>
									{
										answer 
										? (
												<Link
													underline="none"
													className={css.topicLink}
													color="inherit"
													component={RouterLink}
													style={{ pointerEvents: answer ? "all" : "none", cursor: answer ? "pointer" : "initial" }}
													to={`/consenting-process/${consentForm.id}/${link}?item=${index}`}>
													<Box display="flex" alignItems="flex-start" justifyContent="flex-end">
														<Typography
															align="right"
															component="div"
															variant="caption"
															color={active ? "secondary" : answer ? "inherit" : "textSecondary"}
															style={{ fontWeight: active ? "bold" : "normal", marginRight: 10 }}>
															{item.name}
														</Typography>
														{!answer && <RadioButtonUnchecked className={css.icon} style={{ color: "#d8d8d8" }} />}
														{ 
															answer 
															? checkUpdateStatus(answer.updatedAt, item.updatedAt)
																? <Tooltip title="This topic has been updated after you answered it" placement="right-start"><Warning className={css.icon} style={{ color: "#f44336" }} /></Tooltip>
																: answer.understood === true 
																	? <CheckCircle className={css.icon} style={{ color: "#9bd796" }} /> 
																	: <ContactSupport className={css.icon} style={{ color: "#ffc98b" }} />
															: null 
														}
													</Box>
												</Link>
											) 
										: (												
											<Box display="flex" alignItems="flex-start" justifyContent="flex-end">
												<Typography
													align="right"
													component="div"
													variant="caption"
													color={active ? "secondary" : answer ? "inherit" : "textSecondary"}
													style={{ fontWeight: active ? "bold" : "normal", marginRight: 10 }}>
													{item.name}
												</Typography>
												<RadioButtonUnchecked className={css.icon} style={{ color: "#d8d8d8" }} />
											</Box>
										)
									}										
								</ListItem>
							);
						})}
					</List>					
				</Hidden>
			</ListItem>
		);
	}

	const ItemLink = ({ children, disabled, to }: ItemLinkProps) => {
		
		return disabled 
			? <Fragment>{ children }</Fragment>
			:  (
			<Link					
				underline="none"
				className={css.topicLink}
				color="inherit"
				component={RouterLink}
				style={{ pointerEvents: disabled ? "none": "all", cursor: disabled ? "none" : "pointer" }}
				to={to}
			>
				{ children }
			</Link>
		)
	}

	return (
		<Box component="aside" width={225} mr={8} className={css.aside}>				
			<List dense style={{ padding: 0 }}>
				<ItemLink disabled={currentType === TopicType.CONSIDERATIONS || !activateConsiderationsLink()} to={`/consenting-process/${consentForm.id}/considerations`}>
					<Item
						index="1"
						items={considerations}
						name="Considerations"
						link={TopicType.CONSIDERATIONS.toLocaleLowerCase()}
						isActive={currentType === TopicType.CONSIDERATIONS}
					/>
				</ItemLink>
				<ItemLink disabled={ currentType === TopicType.BENEFITS || !activateBenefitsLink()} to={`/consenting-process/${consentForm.id}/benefits`}>
					<Item
						index="2"
						items={benefits}
						name="Benefits"
						link={TopicType.BENEFITS.toLocaleLowerCase()}
						isActive={currentType === TopicType.BENEFITS}
					/>
				</ItemLink>
				<ItemLink disabled={currentType === TopicType.RISKS || !activateRisksLink()} to={`/consenting-process/${consentForm.id}/risks`}>
					<Item
						index="3"
						items={risks}
						name="Risks"
						link={TopicType.RISKS.toLocaleLowerCase()}
						isActive={currentType === TopicType.RISKS}
					/>
				</ItemLink>
				<ItemLink disabled={currentType === "summary" || !activateSummaryLink()} to={`/consenting-process/${consentForm.id}/summary`}>
					<Item
						index="4"
						items={[]}
						name="Summary"
						link="summary"
						isActive={currentType === "summary"}
					/>
				</ItemLink>
				
			</List>
		</Box>
	);
}

export default Navigation;