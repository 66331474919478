import { Reducer } from "react";
import { Procedure } from "../../../models";
import { ProcedureProviderAction } from "./interfaces/ProcedureProviderAction.interface";
import { ProcedureProviderActionType } from "./enums/procedure-provider-action-type.enum";
import { TopicType } from "../../enums/topic-type.enum";

export const ProcedureReducer: Reducer<Procedure, ProcedureProviderAction> = (procedure, action) => {

	const { type, payload } = action;
  switch (type) {

    case ProcedureProviderActionType.SET_PROCEDURE:
			
      return payload;   

    case ProcedureProviderActionType.UPDATE_PROCEDURE:

      return {
        ...procedure,        
        ...payload,
      };

    case ProcedureProviderActionType.SET_QUESTIONS:

      switch (payload.topicType) {
        case TopicType.CONSIDERATIONS:
          
          return {
            ...procedure,
            considerations: procedure.considerations.map(consideration => {
              if(consideration.id !== payload.topicId){
                return consideration;
              }
              
              return {...consideration, questions: payload.questions};
            })
          };
  
        case TopicType.BENEFITS: 

          return {
            ...procedure,
            benefits: procedure.benefits.map(benefit => {
              if(benefit.id !== payload.topicId){
                return benefit;
              }
              return {...benefit, questions: payload.questions};
            })
          };
  
        case TopicType.RISKS:

          return {
            ...procedure,
            risks: procedure.risks.map(risk => {
              if(risk.id !== payload.topicId){
                return risk;
              }
              return {...risk, questions: payload.questions};
            })
          };
      }
      break;

    default:
      return procedure;
  }
}