import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

const palette = createPalette({
	primary: {
		main: "#2594a2",
	},
	secondary: {
		main: "#ec681e"
	},
	text: {
		primary: "#1a1a2f"
	}
});

let theme = createMuiTheme({
	typography: {
		body1: {
			fontSize: 16,
			marginBottom: 8
		},
		body2: {
			fontSize: 14,
			marginBottom: 8
		},
		h1: {
			fontSize: 36,
			marginBottom: 15,
		},
		h2: {
			fontSize: 26,
			marginBottom: 15
		},
		h3: {
			fontSize: 20,
			marginBottom: 15
		},
		h4: {
			fontSize: 18,
			marginBottom: 15
		},
		h5: {
			fontSize: 16,
			marginBottom: 15
		},
		h6: {
			fontSize: 14,
			marginBottom: 15
		}
	},
	shape: {
		borderRadius: 3
	},
	palette: palette,
	// Components
	overrides: {
		MuiDrawer: {
			paper: {
				backgroundColor: `${palette.primary.main}`,
			}
		},
		MuiButton: {
			outlinedPrimary: {
				borderWidth: '2px',
				"&:hover": {
					borderWidth: '2px',
				}
			}
		}
	}
});

theme = responsiveFontSizes(theme);

export default theme;