import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useFetchCSRF from '../../common/hooks/useFetchCSRF';
import useResetPassword from './hooks/use-reset-password';
import LoggedOut from '../templates/logged-out/logged-out';

const useStyles = makeStyles((theme) => ({
	bg: {
		height: "100vh",
		boxSizing: "border-box",
		paddingTop: 50,
		background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
		fallbacks: {
			background: theme.palette.primary.main,
		}
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 25,
		boxSizing: "border-box",
	},
	form: {
		width: '100%'
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	alert: {
		alignSelf: "normal"
	}
}));

const ResetPassword = () => {
	const classes = useStyles();
	const { CSRF } = useFetchCSRF();

	const { error, handleSubmit, loading, onFieldChange, success, values } = useResetPassword();

	const isLoading = loading || CSRF.loading;

	return (
		<LoggedOut title="Request a new password">
			<Container maxWidth="sm" disableGutters style={{ margin: 0 }}>
				<Typography display="block" align="left" component="h1" variant="h2">
					Request a new password
				</Typography>

				{!success &&
					<Box mt={1} mb={1} color="text.secondary">
						<Typography variant="body2">
							Please fill in the email that you used to register. You will be sent an email with instructions to reset your password.
						</Typography>
					</Box>}

				{!success && (
					<form className={classes.form} onSubmit={handleSubmit}>
						<TextField
							type="email"
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="username"
							autoComplete="email"
							autoFocus
							value={values.username}
							onChange={onFieldChange}
						/>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={isLoading}
							className={classes.submit}>
							{isLoading ? <CircularProgress size={25} color="primary" /> : "Send Email"}
						</Button>
					</form>
				)}

				{success &&
					<Alert className={classes.alert} severity="success">
						An email has been sent to you with instructions to reset your password. If you do not see this email in your inbox within a few minutes, please check your spam/junk/clutter.
					</Alert>}

				{error && <Alert className={classes.alert} severity="error">{error}</Alert>}
			</Container>
		</LoggedOut>
	);
}

export default ResetPassword;