import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useQuestionFormStyles = makeStyles((theme: Theme) =>
  createStyles({    
    button: {
      marginRight: 15
    },
    buttons: {
      marginBottom: 15
    },
    '@global':{
      '.MuiFormControlLabel-label': {
        marginBottom: 0
      },
      '.MuiInputBase-multiline':{
        backgroundColor: "white" 
      }
    },
    invalidData:{
      color: 'red',
    },
    options: {
      flexDirection: 'row'
    }
  }),
);