import { ConsentFormStatus } from "../../../../common/enums/consent-form-status.enum";

export default function useStatus() {	
	
  function getColor(status: string): string {
		switch (status) {
			case ConsentFormStatus.NOT_STARTED:
				return "red";

			case ConsentFormStatus.COMPLETE:
				return "green";

			case ConsentFormStatus.IN_PROGRESS:
				return "gold";

			case ConsentFormStatus.PENDING_CONSULTANT_SIGNATURE:
			case ConsentFormStatus.PENDING_PATIENT_SIGNATURE:
				return "lightblue";

			case ConsentFormStatus.PENDING_CONSULTANT_SUBMISSION:
			case ConsentFormStatus.PENDING_PATIENT_SUBMISSION:
				return "darkgray";

			case ConsentFormStatus.QA_NOT_UNDERSTOOD:
			case ConsentFormStatus.PENDING_QUERIES:
			case ConsentFormStatus.PATIENT_NOTIFIED:
			case ConsentFormStatus.PENDING_QAS:
				return "orange";

			default:
				return "red";
		}
	}

	return { getColor }
}