import { Procedure } from "./procedure.model";
import { SubSpeciality } from "./sub-speciality.model";
import { User } from "./user.model";

export interface Speciality {
	id: string;
	name: string;

  //relations  
  subSpecialities: SubSpeciality[];
  users: User[];
  procedures: Procedure[];
}

export const initialSpeciality: Speciality = {
	id: "",
	name: "",

  //relations  
  subSpecialities:[],
  users: [],
  procedures: [],
}