import { useEffect, useState } from "react";


interface iCSRF {
	token: string | null;
	error: string | null;
	loading: boolean;
}

export default function useFetchCSRF() {
	const [CSRF, setCSRF] = useState<iCSRF>({ token: null, error: null, loading: false });

	// useEffect(() => {
	// 	const fetchCSRF = async () => {
	// 		try {
	// 			setCSRF(csrf => ({ ...csrf, loading: true }));
	// 			const res = await apiInstance.get("/auth");

	// 			// Fetch a CSRF token to use with this session
	// 			if (res.data.csrf) {
	// 				sessionStorage.setItem("csrf", res.data.csrf);
	// 				setCSRF(csrf => ({ ...csrf, token: res.data.csrf }));
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 			setCSRF(csrf => ({ ...csrf, error: "Error generating token. Please refresh the page and try again." }));
	// 		} finally {
	// 			setCSRF(csrf => ({ ...csrf, loading: false }));
	// 		}
	// 	}

	// 	fetchCSRF();
	// }, []);

	return { CSRF }
}