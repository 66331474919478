import React from "react";

import { Button, FormControl, makeStyles, TextField } from "@material-ui/core";
import useInteractionForm from "./hooks";
import { Interaction } from "../../../models/interaction.model";

interface Props {
  addInteraction: (interaction: Interaction) => void;
  answerId: string;
  canCancel?: boolean;
  hideForm: () => void;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',    
  },
  textBox: {
      width: '100%'
  }
}));

const InteractionForm = ({ addInteraction, canCancel=true, answerId, hideForm }: Props) => {

  const css = useStyles();
  const { comment, handleChange, handleSubmit } = useInteractionForm({addInteraction, answerId, hideForm});
  
	return (
    <form onSubmit={handleSubmit}>
      <FormControl className={css.textField}>
        <TextField
            className={css.textBox}
            required
            label="Your comment:"
            multiline
            rows={4}
            variant="outlined"
            name="patientComment"
            value={comment}
            onChange={handleChange}
        />
      </FormControl>
      <Button
        disabled={ comment === "" }
        type="submit"
        variant="contained"
        color="primary">
        Send Question
      </Button>
      {
        canCancel
        ? <Button
            style={{ marginLeft: 15 }}
            variant="contained"
            color="secondary"
            onClick={hideForm}>
            Cancel
          </Button>
        : null
      }
    </form>
	);
};

export default InteractionForm;